<div class="row justify-content-center">
  <!-- FILTRE DE RECHERCHE -->
  <app-filter class="width"
    [dateD]="true"
    [dateF]="true"
    [name]="true"
    [nameTitle]="'Locataire'"
    [type]="true"
    [isPrint]="isPrint"
    [typeRow]="typeRow" (typeEvent)="onChangeLoad($event)" (filterEvent)="onFilter($event)" (printerEvent)="onPrinter($event)">
  </app-filter>

  <!-- WIDGET DES NOMBRES -->
  <div class="col-xl-3 col-md-6">
    <app-card [hidHeader]="true">
      <div class="row align-items-center m-l-0">
        <div class="col-auto"> <i class="icon feather icon-users f-30 text-c-red"></i> </div>
        <div class="col-auto">
          <h6 class="text-muted m-b-10">{{ widget?.info?.nbrL > 1 ? 'Locataires' : 'Locataire' }}</h6>
          <h2 class="m-b-0">{{ widget?.info?.nbrL }}</h2>
        </div>
      </div>
    </app-card>
  </div>
  <div class="col-xl-3 col-md-6">
    <app-card [hidHeader]="true">
      <div class="row align-items-center m-l-0">
        <div class="col-auto">
          <i class="icon feather icon-file-text f-30 text-c-purple"></i>
        </div>
        <div class="col-auto">
          <h6 class="text-muted m-b-10">{{ widget?.info?.nbrC > 1 ? 'Contrats' : 'Contrat' }}</h6>
          <h2 class="m-b-0">{{ widget?.info?.nbrC }}</h2>
        </div>
      </div>
    </app-card>
  </div>
  <div class="col-xl-3 col-md-6">
    <app-card [hidHeader]="true">
      <div class="row align-items-center m-l-0">
        <div class="col-auto">
          <i class="icon feather icon-package f-30 text-c-green"></i>
        </div>
        <div class="col-auto">
          <h6 class="text-muted m-b-10">{{ widget?.info?.nbrF > 1 ? 'Factures' : 'Facture' }}</h6>
          <h2 class="m-b-0">{{ widget?.info?.nbrF }}</h2>
        </div>
      </div>
    </app-card>
  </div>
  <div class="col-xl-3 col-md-6">
    <app-card [hidHeader]="true">
      <div class="row align-items-center m-l-0">
        <div class="col-auto">
          <i class="icon feather icon-credit-card f-30 text-c-blue"></i>
        </div>
        <div class="col-auto">
          <h6 class="text-muted m-b-10">{{ widget?.info?.nbrP > 1 ? 'Paiements' : 'Paiement' }}</h6>
          <h2 class="m-b-0">{{ widget?.info?.nbrP }}</h2>
        </div>
      </div>
    </app-card>
  </div>

  <!-- WIDGET SECONDAIRES -->
  <div class="col-md-6">
    <div class="row">
      <div class="col-md-12">
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-purple">
          <div class="row align-items-blue">
            <div class="col">
              <h6 class="text-muted m-b-0">{{ widgetTotalTitle }}</h6>
            </div>
          </div>
          <div class="app-card-footer">
            <div class="row align-items-center">
              <div class="col-9">
                <h4 class="text-white m-b-0">{{ widget?.invoice?.total?.mtnT |number}} {{global.device}}</h4>
              </div>
              <div class="col-3">
                <h4 class="text-white m-b-0">{{ widget?.invoice?.total?.nbrT }}</h4>
              </div>
            </div>
          </div>
        </app-card>
      </div>
      <div class="col-md-6">
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-blue">
          <div class="row align-items-blue">
            <div class="col">
              <h6 class="text-muted m-b-0">{{ widgetAttenteTitle }}</h6>
            </div>
          </div>
          <div class="app-card-footer">
            <div class="row align-items-center">
              <div class="col-9">
                <h5 class="text-white m-b-0">{{ widget?.invoice?.attente?.mtnA |number}} {{global.device}}</h5>
              </div>
              <div class="col-3">
                <h4 class="text-white m-b-0">{{ widget?.invoice?.attente?.nbrA }}</h4>
              </div>
            </div>
          </div>
        </app-card>
      </div>
      <div class="col-md-6">
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-red">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="text-muted m-b-0">{{ widgetImpayeTitle }}</h6>
            </div>
          </div>
          <div class="app-card-footer">
            <div class="row align-items-center">
              <div class="col-9">
                <h5 class="text-white m-b-0">{{ widget?.invoice?.impaye?.mtnI |number}} {{global.device}}</h5>
              </div>
              <div class="col-3">
                <h4 class="text-white m-b-0">{{ widget?.invoice?.impaye?.nbrI }}</h4>
              </div>
            </div>
          </div>
        </app-card>
      </div>
      <div class="col-md-6">
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-yellow">
          <div class="row align-items-blue">
            <div class="col">
              <h6 class="text-muted m-b-0">{{ widgetCoursTitle }}</h6>
            </div>
          </div>
          <div class="app-card-footer">
            <div class="row align-items-center">
              <div class="col-9">
                <h5 class="text-white m-b-0">{{ widget?.invoice?.cours?.mtnE | number}} {{global.device}}</h5>
              </div>
              <div class="col-3">
                <h4 class="text-white m-b-0">{{ widget?.invoice?.cours?.nbrE }}</h4>
              </div>
            </div>
          </div>
        </app-card>
      </div>
      <div class="col-md-6">
        <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-green">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="text-muted m-b-0">{{ widgetSoldeTitle }}</h6>
            </div>
          </div>
          <div class="app-card-footer">
            <div class="row align-items-center">
              <div class="col-9">
                <h5 class="text-white m-b-0">{{ widget?.invoice?.solde?.mtnS |number}} {{global.device}}</h5>
              </div>
              <div class="col-3">
                <h4 class="text-white m-b-0">{{ widget?.invoice?.solde?.nbrS }}</h4>
              </div>
            </div>
          </div>
        </app-card>
      </div>
    </div>
  </div>

  <!-- DIAGRAMME CIRCULAIRE -->
  <div class="col-md-6">
    <app-card cardTitle="DIAGRAMME CIRCULAIRE DES MONTANTS, TOTAL, IMPAYE ET PAYEE" [options]="false">
      <div #doughnutChart>
        <chart id="doughnut_chart" [type]="'doughnut'" [data]="pieChartData" style="height: 290px;"></chart>
      </div>
    </app-card>
  </div>

  <!-- DIAGRAMME EN BAR -->
  <div class="col-md-12 mb-4">
    <lib-highcharts-angular [Highcharts]="Highcharts" [options]="barBasicChartOptions" style="width: 100%; height: 400px; display: block;"></lib-highcharts-angular>
  </div>

  <!-- DIFERRENTS TABLEAU DE LISTING PART 1 -->
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-6">
        <app-card [hidHeader]="true">
          <div class="col-md-12">
            <span class="badge badge-danger my-2 f-14 formBadge width"> LISTE DES 10 DERNIERS CONTRACTS ACTIF </span>
          </div>
          <div class="col-md-12">
            <div *ngIf="etat && contractA &&  contractA.length > 0" class="table-responsive">
              <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>Référence</th>
                    <th>Locataire</th>
                    <th>Locative</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let contract of contractA" (click)="showContract(contract)">
                    <td class="align-middle">
                      <p class="m-0 d-inline-block align-middle font-16">{{ contract?.code }}</p>
                    </td>
                    <td>
                      <p class="m-0 d-inline-block align-middle font-16">
                        <span>{{ contract?.tenant?.searchableTitle }}</span>
                      </p>
                    </td>
                    <td>{{ contract?.rental?.libelle }}</td>
                    <td>{{ contract?.type }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <app-no-data class="width" [title]="'Aucun contrat trouvé'" *ngIf="contractA && contractA.length === 0"></app-no-data>
          </div>
        </app-card>
      </div>
      <div class="col-md-6">
        <app-card [hidHeader]="true">
          <div class="col-md-12">
            <span class="badge badge-warning my-2 f-14 formBadge width"> LISTE DES 10 PROCHAIN CONTRACTS A RENOUVELLE </span>
          </div>
          <div class="col-md-12">
            <div *ngIf="etat && contractRe &&  contractRe.length > 0" class="table-responsive">
              <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>Référence</th>
                    <th>Locataire</th>
                    <th>Locative</th>
                    <th>Periode</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let itemCre of contractRe" (click)="showContract(itemCre)">
                    <td class="align-middle">
                      <p class="m-0 d-inline-block align-middle font-16">{{ itemCre?.code }}</p>
                    </td>
                    <td>
                      <p class="m-0 d-inline-block align-middle font-16">
                        <span>{{ itemCre?.tenant?.searchableTitle }}</span>
                      </p>
                    </td>
                    <td>{{ itemCre?.rental?.libelle }}</td>
                    <td>{{ itemCre?.periode }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <app-no-data class="width" [title]="'Aucun contrat trouvé'" *ngIf="contractRe && contractRe.length === 0"></app-no-data>
        </div>
        </app-card>
      </div>
    </div>
  </div>

  <!-- DIFERRENTS TABLEAU DE LISTING PART 2 -->
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-6">
        <app-card [hidHeader]="true">
          <div class="col-md-12">
            <span class="badge badge-success my-2 f-14 formBadge width"> LISTE DES LOCATAIRES AYANT AU MOINS UN IMPAYE </span>
          </div>
          <div class="col-md-12">
            <div *ngIf="etat && tenantI.length > 0" class="table-responsive">
              <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>Nom</th>
                    <th>Impayé</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of tenantI" (click)="showTenant(item)">
                    <td class="align-middle">
                      <p class="m-0 d-inline-block align-middle font-16">
                        <span>{{ item?.searchableTitle }}</span>
                        <span class="m-0 d-inline-block align-middle">
                          <i class="fa fa-phone"></i>{{ item?.telephone }} - {{ item?.type }}
                        </span><br/>
                        <span> Code : {{ item?.code }}</span>
                      </p>
                    </td>
                    <td>{{ item?.impaye|number }} {{ global.device }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="font-weight-bold font-size-40 text-right">TOTAL</td>
                    <td class="font-weight-bold font-size-40"> {{totalTenantI |number}} {{global.device}}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <app-no-data class="width" [title]="'Aucun locataire trouvé'" *ngIf="tenantI && tenantI.length === 0"></app-no-data>
          </div>
        </app-card>
      </div>
      <div class="col-md-6">
        <app-card [hidHeader]="true">
          <div class="col-md-12">
            <span class="badge badge-primary my-2 f-14 formBadge width"> LISTE DES 10 DERNIERS CONTRATS RESILIE </span>
          </div>
          <div class="col-md-12">
            <div class="table-responsive">
              <table *ngIf="etat && contractR.length > 0" datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                <thead class="thead-light">
                <tr>
                  <th>Référence</th>
                  <th>Nom</th>
                  <th>Type</th>
                  <th>Impayé</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let itemCr of contractR" (click)="showContract(itemCr)">
                    <td class="align-middle">
                      <p class="m-0 d-inline-block align-middle font-16">{{ itemCr?.code }}</p>
                    </td>
                    <td>
                      <p class="m-0 d-inline-block align-middle font-16">
                        <span>{{ itemCr?.searchableTitle }}</span>
                      </p>
                    </td>
                    <td>{{ itemCr?.type }}</td>
                    <td>{{ itemCr?.impaye }} {{ global.device }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <app-no-data class="width" [title]="'Aucun contrat résilié trouvé'" *ngIf="contractR && contractR.length === 0"></app-no-data>
          </div>
        </app-card>
      </div>
    </div>
  </div>
</div>
