import {Electricite} from '@model/electricite';
import { Globals } from '@theme/utils/globals';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Component, Input, OnInit} from '@angular/core';
import {EmitterService} from '@service/emitter/emitter.service';
import {ElectriciteService} from '@service/electricite/electricite.service';
import {DateHelperService} from '@theme/utils/date-helper.service';
import {ElectriciteAddComponent} from '@locataire/electricite/electricite-add/electricite-add.component';
import {ElectriciteShowComponent} from '@locataire/electricite/electricite-show/electricite-show.component';

@Component({
  selector: 'app-electricite-list',
  templateUrl: './electricite-list.component.html',
  styleUrls: ['./electricite-list.component.scss']
})
export class ElectriciteListComponent implements OnInit {
  @Input() electricites: Electricite[];
  @Input() action: boolean = true
  @Input() locataire = true;
  dtOptions: any = {};
  etat: boolean = false
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user
  total = 0;
  paye = 0;
  impaye = 0;

  constructor(
    private modalService: NgbModal,
    private emitter: EmitterService,
    private electriciteService: ElectriciteService
  ) {
  }

  ngOnInit(): void {
    this.etat = this.electricites ? true : false;
    this.dtOptions = Globals.dataTable;
    this.emitter.event.subscribe((data) => {
      if (data.action === 'CONTRACT_ADD') {
        this.appendToList(data.payload);
      }
      if (data.action === 'CONTRACT_UPDATED') {
        this.update(data.payload);
      }
    });
  }
  appendToList(row): void {
    this.electricites.unshift(row);
  }
  update(row): void {
    const index = this.electricites.findIndex(x => x.uuid === row.uuid);
    if (index !== -1) {
      this.electricites[index] = row;
    }
  }
  edit(row): void {
    this.electriciteService.edit = true;
    this.electriciteService.setElectricite(row);
    this.modal(ElectriciteAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  show(row): void {
    this.electriciteService.setElectricite(row);
    this.modal(ElectriciteShowComponent, 'modal-basic-title', 'xl', true, 'static')
  }
  printer(row): void {
    this.electriciteService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  delete(row) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.electriciteService.getDelete(row.uuid).subscribe((res: any) => {
          const index = this.electricites.findIndex((x) => {
            return x.uuid === row.uuid;
          });
          if (index !== -1) {
            this.electricites.splice(index, 1);
          }
          Swal.fire('Contrat Supprimé', res?.message, 'success');
        });
      }
    });
  }
  modal(component, type, size, center, backdrop): void {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {

    }, (reason) => {

    });
  }
  readableDate(date) { return DateHelperService.readable(date); }
  formatDate(date) { return DateHelperService.fromJsonDate(date); }
  timelapse(date): string { return DateHelperService.getTimeLapse(date); }
}
