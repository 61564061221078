<div class="col-sm-12">
  <app-card [hidHeader]="true" cardClass="card-datatable">
    <form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
      <div *ngIf="validate && dataSelected.length>0" class="btn-rental float-right mb-2">
        <button (click)="onConfirme()"  type="submit" class="btn btn-success m-1">
          Valider <i class="feather icon-check-square"></i>
        </button>
      </div>
    <div class="table-responsive">
      <table *ngIf="etat" datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
        <tr>
          <th>
            <div class="form-group">
              <div class="form-check">
                <input id="selectAll" class="form-check-input" formControlName="valueOne" type="checkbox" id="checkAll"
                (change)="onCheckAll($event)">
              </div>
            </div>
          </th> 
          <th>Locative</th>
          <th *ngIf="locataire">Locataire</th>
          <th>Type</th>
          <th>Etat</th>
          <th>Crée le</th>
          <th *ngIf="action">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of inventories; let i = index">
             <td>
              <div class="form-group">
                <div class="form-check">
                  <input formControlName="checked" class="form-check-input"  type="checkbox" id="check{{i}}"
                  (change)="onCheckItem(item)">
                </div>
              </div>
            </td> 
          <td class="align-middle">
            <p class="m-0 d-inline-block align-middle font-16">
              <span class="text-warning">{{item.contract.rental?.libelle}}</span> <br />
              Superficie : {{item.contract.rental.superficie}} m² - {{item.contract.rental.piece}} pièce(s): <br />
              Propriétaire : {{item.contract.rental?.house?.owner?.nom}}
            </p>
          </td>
          <td *ngIf="locataire">
            <p class="m-0 d-inline-block align-middle font-16">
              <span class="text-warning">{{item?.contract?.tenant?.nom}}</span> <br />
              Type : {{item?.contract?.tenant?.type}}<br />
              Telephone : {{item?.contract?.tenant?.telephone}}
            </p>
          </td>
          <td>
            <span class="badge " [ngClass]="{
            'badge-light-success' : item.type === 'ENTREE',
            'badge-light-danger' : item.type === 'SORTIE'
            }"><small>{{item.type}}</small></span>
          </td>
          <td>
            <span class="badge" [ngClass]="{
            'badge-success' : item.etat === 'VALIDE',
            'badge-warning' : item.etat === 'ATTENTE'
            }">{{validation(item.etat)}}</span>
          </td>
          <td>{{item?.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
          <td *ngIf="action" class="table-action">
            <div class="overlay-edit">
              <button (click)="showInventory(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                <i class="fas fa-eye"></i>
              </button>
              <ng-template ngxPermissionsOnly="INVENTORY:EDIT">
                <button *ngIf="item.etat ==='ATTENTE'" (click)="editInventory(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                  <i class="feather icon-edit-2"></i>
                </button>
              </ng-template>
                <button *ngIf="item.etat === 'ATTENTE' && validate" (click)="validateInventory(item)" type="button" class="btn btn-icon btn-success ml-1" ngbTooltip="Valider">
                  <i class="fas fa-check"></i>
                </button>
              <ng-template ngxPermissionsOnly="INVENTORY:PRINTER:SHOW">
                <button *ngIf="item.etat === 'VALIDE'" (click)="printerInventory(item)" type="button" class="btn btn-icon btn-warning ml-1"
                  ngbTooltip="Imprimer">
                  <i class="feather icon-printer"></i>
                </button>
              </ng-template>
              <button type="button" (click)="uploadInventory(item)" class="btn btn-icon btn-info ml-1" ngbTooltip="charger le contrat signé">
                <i class="feather icon-upload-cloud"></i>
              </button>
              <ng-template ngxPermissionsOnly="INVENTORY:DELETE">
                <button *ngIf="item?.contract?.etat !== 'RESILIE'" type="button" (click)="delete(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                  <i class="feather icon-trash"></i>
                </button>
              </ng-template>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </form>
  </app-card>
</div>
