import {Invoice} from '@model/invoice';
import {Contract} from '@model/contract';
import { Globals } from '@theme/utils/globals';
import { environment } from '@env/environment';
import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxPermissionsService } from 'ngx-permissions';
import {EmitterService} from '@service/emitter/emitter.service';
import {ContractService} from '@service/contract/contract.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { UploaderService } from '@service/uploader/uploader.service';
import {ContractAddComponent} from '@locataire/contract/contract-add/contract-add.component';

@Component({
  selector: 'app-contract-show',
  templateUrl: './contract-show.component.html',
  styleUrls: ['./contract-show.component.scss']
})
export class ContractShowComponent implements OnInit {
  title = '';
  contract: Contract;
  invoice: Invoice
  publicUrl = environment.publicUrl;
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user
  file: any;
  validate = false;

  constructor(
    public modale: NgbActiveModal,
    private modalService: NgbModal,
    private emitter: EmitterService,
    private uploader: UploaderService,
    private contractService: ContractService,
    private permissionsService: NgxPermissionsService
  ) {
    this.contract = this.contractService.getContract()
    this.validate = this.contractService.validated
    this.title = "Détails du " + this.contract?.libelle
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
  }

  ngOnInit(): void {
  }

  editContract(row) {
    this.modalService.dismissAll();
    this.contractService.setContract(row);
    this.contractService.edit = true;
    this.modal(ContractAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  activateContract(row) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment valider ce contrat ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Valider <i class="fas fa-check"></i>',
      confirmButtonColor: 'green',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
          this.contractService.activate(row).subscribe((res) => {
            if (res?.status === 'success') {
              this.modale.close('ferme');
              this.emitter.emit({action: 'CONTRACT_ACTIVATE', payload: res?.data});
            }
        });
      }
    });
  }
  printerContract(row): void {
    this.contractService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  showFile(item) {
    const fileByFolder = this.uploader.getDataFileByFolder();
    this.file = fileByFolder?.path ? this.publicUrl + '/' + JSON.parse(localStorage.getItem('token-zen-data'))?.path + 'signe/contrat/' + fileByFolder?.path : null;
  }
  closeViewer() {
    this.file = '';
    this.uploader.setDataFileByFolder('');
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {

    }, (reason) => {

    });
  }
}
