import { NgxPermissionsService } from 'ngx-permissions';
import { Component, OnInit } from '@angular/core';
import { Electricite } from '@model/electricite';
import { PAYMENT } from '@theme/utils/functions';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ElectriciteService } from '@service/electricite/electricite.service';
import { Globals } from '@theme/utils/globals';

@Component({
  selector: 'app-electricite-show',
  templateUrl: './electricite-show.component.html',
  styleUrls: ['./electricite-show.component.scss']
})
export class ElectriciteShowComponent implements OnInit {
  title: string = ""
  electricite: Electricite
  global = { country: Globals.country, device: Globals.device }
  userSession = Globals.user
  payment = PAYMENT

  constructor(
    public modale: NgbActiveModal,
    private modalService: NgbModal,
    private electriciteService: ElectriciteService,
    private permissionsService: NgxPermissionsService
  ) {
    this.electricite = this.electriciteService.getElectricite()
    this.title = "Détails de la consommation du " + this.electricite.rent.mois
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
  }

  ngOnInit(): void {
  }
  printerElectricite(row): void {
    this.electriciteService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
}
