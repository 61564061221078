<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
  <div class="modal-body">
    <div class="col-md-12">
      <div>
        <!-- DETAILS SUR L'ENREGISTREMENT -->
        <div class="row">
          <span class="badge badge-danger my-2 f-14 formBadge width">
            DETAILS SUR LA PERIODE
          </span>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="periode">Periode de consommations (MOIS) <span class="asterix">*</span></label>
            <input type="date" formControlName="periode" [readOnly]="edit" class="form-control" id="periode" [ngClass]="{
              'is-invalid': submit && f.periode.errors,
              'is-valid': submit && f.periode.valid}">
            <div class="invalid-feedback" *ngIf="submit && f.periode.errors">
              <div *ngIf="f.periode.errors.required">{{required.novide}}</div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="date">Date de prélèvement  <span class="asterix">*</span></label>
            <input type="date" formControlName="date" [readOnly]="edit" class="form-control" id="date" [ngClass]="{
              'is-invalid': submit && f.date.errors,
              'is-valid': submit && f.date.valid}">
            <div class="invalid-feedback" *ngIf="submit && f.date.errors">
              <div *ngIf="f.date.errors.required">{{required.novide}}</div>
            </div>
          </div>
        </div>
        <div >
          <!-- INVENTAIRE DES ELEMENTS PRESENTS -->
          <div class="row">
            <span class="badge badge-danger my-2 f-14 formBadge width"> INFORMATIONS SUR LES CONSOMMATIONS</span>
          </div>
          <div formArrayName="options"  *ngIf="options.controls.length > 0">
            <!-- LIBELLE DES CHAMPS EQUIPEMENT -->
            <div class="row">
              <div class="col-md-5">
                <label>Contrat <span class="asterix">*</span></label>
              </div>
              <div class="col-md-2">
                <label>Index de départ  </label>
              </div>
              <div class="col-md-2">
                <label>Index de fin  </label>
              </div>
              <div class="col-md-2">
                <label>Consommation </label>
              </div>
            </div>
            <div class="form-group" *ngFor="let item of options.controls; let i=index" [formGroupName]="i">
              <div class="row">
                <div class="col-md-5">
                  <select class="form-control" [disabled]="edit" id="contract{{i}}" formControlName="contract" [ngClass]="{
                    'is-invalid': submit && f.contract.errors, 'is-valid': submit && f.contract.valid }">
                    <option [ngValue]="null">Selectionnez un contrat</option>
                    <option *ngFor="let item of contracts" [value]="item?.uuid">{{item.search}} </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <input type="text" (change)="onChange()" formControlName="debut" class="form-control" id="debut{{i}}" placeholder="Index de départ ">
                </div>
                <div class="col-md-2">
                  <input type="text" (change)="onChange()" formControlName="fin" class="form-control" id="fin{{i}}" placeholder="Index de fin ">
                </div>
                <div class="col-md-2">
                  <input type="text" formControlName="conso" class="form-control" id="conso{{i}}" placeholder="consommations" readonly>
                </div>
                <div class="col-md-1">
                  <button (click)="onDelete(i)" type="button" class="btn btn-danger">
                    <i class="feather icon-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-12 text-right">
              <button class="btn btn-msg-send btn-primary ml-auto"
              (click)="onAdd(5)" type="button"> Ajouter <i class="fa fa-plus"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="onClose()" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="onReset()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="form.invalid"  type="submit" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</form>
