<div class="col-md-12" *ngIf="homes.length > 0">
  <span class="badge badge-secondary mb-4 f-14 width">LISTE DES MAISONS</span>

  <div class="row">
    <div class="col-lg-4 col-md-4" *ngFor="let item of homes">
      <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false" cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
        <div class="app-card-header">
          <div class="row align-items-end">
            <div class="col text-left pb-3"></div>
            <div class="col text-right pb-3">
              <span class="badge" [ngClass]="{
                'badge-success' : item?.etat === 'DISPONIBLE',
                'badge-warning' : item?.etat === 'RESERVE',
                'badge-danger' : item?.etat === 'VENDU'
                }">
                {{ item?.etat }}
              </span>
            </div>
          </div>
          <div class="cover-img-block">
            <img [src]="item?.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/house-default.png'" alt="{{ item?.libelle }}"
              title="{{ item?.libelle }}" onerror="this.onerror=null; this.src='assets/images/house-default.png'" class="img-fluid size-img-house">
          </div>
        </div>
        <div class="text-center">
          <p *ngIf="item?.folderCustomer?.modalite === 'ECHEANCE'">
            <ngb-progressbar type="primary" [value]="item?.folderCustomer?.prcEcheancier" ngbTooltip="Echéance"></ngb-progressbar>
          </p>
          <p *ngIf="item?.folderCustomer?.modalite === 'AVANCEMENT'">
            <ngb-progressbar type="primary" [value]="item?.folderCustomer?.prcEtatA" ngbTooltip="Etat d'avancement"></ngb-progressbar>
          </p>
          <h5 class="mb-1 mt-3">
            <small class="text-muted sz-10 font-weight-bold" *ngIf="item?.etat !== 'DISPONIBLE'">
              {{ item?.etat === 'VENDU' ? 'Acquereur' : 'Réservé par' }}: {{ item?.acquereur }}
            </small>
          </h5>
          <h5 class="mb-1 mt-3  text-muted text-primary font-weight-bold">{{ item?.label }}</h5>
          <h5 class="mb-1 mt-3">Ilot N°{{ item?.ilot }} - Lot N°{{ item?.lot }}</h5>
          <h5 class="mb-1 mt-3">Code: {{ item?.code }}</h5>
          <h6 *ngIf="item?.promotion?.parent == null" class="mb-1 mt-3">Programme: {{ item?.promotion?.libelle }} </h6>
          <div *ngIf="item?.promotion?.parent != null">
            <h6  class="mb-1 mt-3">Sous Programme: {{ item?.promotion?.libelle }} </h6>
            <h6  class="mb-1 mt-3">Programme: {{ item?.promotion?.parent?.libelle }} </h6>
          </div>
        </div>
        <div class="row align-items-end mb-2">
          <div class="col text-left">
            <p class="mb-1 text-muted">
              Superficie : {{ item?.superficie }} m²
            </p>
          </div>
          <div class="col text-right">
            <p class="mb-1 text-muted text-warning font-weight-bold">
              Montant : {{ item?.montant | number}} {{global.device}}
            </p>
          </div>
        </div>
        <div class="row align-content">
          <div class="col text-right">
            <button (click)="showHome(item)" type="button" class="btn btn-icon btn-secondary"
              ngbTooltip="Détails">
              <i class="fas fa-eye"></i>
            </button>
            <button *ngxPermissionsOnly="'HOME:EDIT'" (click)="editHome(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
              <i class="feather icon-edit-2"></i>
            </button>
            <button *ngxPermissionsOnly="'HOME:PRINTER:SHOW'" (click)="printerHome(item)" type="button" class="btn btn-icon btn-warning ml-1"
              ngbTooltip="Imprimer">
              <i class="feather icon-printer"></i>
            </button>
            <ng-template ngxPermissionsOnly="HOME:DELETE">
              <button *ngIf="item?.etat === 'DISPONIBLE'" (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                <i class="feather icon-trash"></i>
              </button>
            </ng-template>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</div>
