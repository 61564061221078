<div class="modal-header" *ngIf="etat">
  <h5 class="modal-title">LISTE DES RAPPORTS</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="modale.close('ferme')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
  <div class="col-md-12">
    <span class="badge badge-success my-2 f-14 formBadge width"> LISTE DES RAPPORTS </span>
  </div>
  <div class="col-sm-12" *ngIf="reports && reports.length > 0">
    <app-card [hidHeader]="true" cardClass="card-datatable">
      <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
          <thead class="thead-light">
            <tr>
              <th *ngIf="!etat">Programme</th>
              <th *ngIf="!etat">Maison</th>
              <th>Date</th>
              <th>Libellé</th>
              <th>Type</th>
              <th>Crée le</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of reports">
              <td *ngIf="!etat">
                <p class="m-0 d-inline-block align-middle font-16">
                  <span class="text-warning"> {{item.promotion}}</span> <br />
                  </p>
              </td>
              <td *ngIf="!etat">
                <p class="m-0 d-inline-block align-middle font-16">
                  <span class="text-primary">{{item?.libelleHome}}</span>
                  </p>
              </td>

              <td>{{item?.dateD|date}}</td>
              <td>{{item?.libelle}}</td>
              <td>
                <span class="badge title-show badge-success">{{item?.type}}
                </span>
              </td>
              <td>{{item.createdAt|date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
              <td class="table-action">
                <div class="overlay-edit">
                  <button (click)="showDetailsReport(item)"  type="button" class="btn btn-icon btn-secondary ml-1"
                    ngbTooltip="Détails">
                    <i class="fas fa-eye"></i>
                  </button>
                  <button (click)="editReport(item)" type="button" class="btn btn-icon btn-info ml-1"
                    ngbTooltip="Modifier">
                    <i class="feather icon-edit-2"></i>
                  </button>
                  <button type="button" (click)="delete(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                    <i class="feather icon-trash"></i>
                  </button>
                  <button (click)="printer(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                    <i class="feather icon-printer"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </app-card>
  </div>
  <div class="modal-footer" *ngIf="etat">
    <button (click)="modale.close('ferme')" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
  </div>
