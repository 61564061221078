<div class="row justify-content-center">
  <!-- FILTRE DE RECHERCHE -->
  <div class="width btn-filter-customer">
    <ng-template [ngxPermissionsOnly]="['CUSTOMER:LIST', 'FOLDER:LIST', 'PAYMENT:CUSTOMER:LIST', 'MUTATE:LIST', 'FOLDER:TERMINATE:LIST', 'INVOICE:FOLDER:LIST']">
      <app-filter class="width"
        [name]="true"
        [nameTitle]="nameTitle"
        [user]="true"
        [userTitle]="userTitle"
        [etat]="true"
        [etatRow]="etatRow"
        [etatTitle]="etatTitle"
        [categorie]="true"
        [categorieRow]="categorieRow"
        [categorieTitle]="categorieTitle"
        [dateD]="true"
        [dateF]="true"
        [create]="true"
        [min]="true"
        [minTitle]="minTitle"
        [max]="true"
        [maxTitle]="maxTitle"
        [ordre]="true"
        [code]="true"
        [count]="true"
        [type]="true"
        [typeRow]="typeRow" (typeEvent)="onChangeLoad($event)" (filterEvent)="onFilter($event)">
      </app-filter>
    </ng-template>
  </div>
  <ng-template [ngxPermissionsExcept]="['CUSTOMER:LIST', 'FOLDER:LIST', 'PAYMENT:CUSTOMER:LIST', 'MUTATE:LIST', 'FOLDER:TERMINATE:LIST', 'INVOICE:FOLDER:LIST']">
    <div class="col-md-12">
      <app-no-droit [title]="'client'"></app-no-droit>
    </div>
  </ng-template>

  <!-- BOUTONS DE GESTION -->
  <div class="col-md-12 mb-4 btn-all-customer">
    <div class="first">
      <div class="second">
        <div class="btn-customer">
          <div *ngxPermissionsOnly="['CUSTOMER:NEW']" class="btn-group m-1" ngbDropdown ngbTooltip="Cliquez ici pour ajouter un client">
            <button class="btn btn-primary" ngbDropdownToggle type="button">
              Ajouter un client <i class="feather icon-user"></i>
            </button>
            <div ngbDropdownMenu>
              <a class="dropdown-item btn-add-entr" (click)="addCustomer('PARTICULIER')">PARTICULIER</a>
              <a class="dropdown-item" (click)="addCustomer('ENTREPRISE')">ENTREPRISE</a>
            </div>
          </div>
        </div>
        <div class="btn-folder">
          <div *ngxPermissionsOnly="['FOLDER:NEW']" class="btn-group m-1" ngbDropdown ngbTooltip="Cliquez ici pour ajouter un dossier">
            <button class="btn btn-secondary m-1" ngbDropdownToggle type="button">
              Dossier <i class="feather icon-folder"></i>
            </button>
            <div ngbDropdownMenu>
              <a *ngxPermissionsOnly="['FOLDER:NEW']" class="dropdown-item" (click)="addFolder()">Ajouter</a>
              <a *ngxPermissionsOnly="['MUTATE:NEW']" class="dropdown-item" (click)="addMutate()">Muter</a>
              <a *ngxPermissionsOnly="['FOLDER:TERMINATE:NEW']" class="dropdown-item" (click)="addTerminate()">Résilier</a>
            </div>
          </div>
        </div>
        <!-- <div class="btn-payment" data-hint="" data-hintposition="top-right"> -->
        <div class="btn-payment">
          <button *ngxPermissionsOnly="['PAYMENT:CUSTOMER:NEW']" (click)="addPayment()" type="button" class="btn btn-success m-1" ngbTooltip="Cliquez ici pour ajouter un paiement">
            Enregister un paiement <i class="feather icon-credit-card"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="btn-group float-right m-1 btn-outil-customer" ngbDropdown>
      <button class="btn btn-warning" ngbDropdownToggle type="button">
        Outils <i class="fas fa-tools"></i>
      </button>
      <div class="dropdown-menu-right" ngbDropdownMenu>
        <a *ngxPermissionsOnly="['CUSTOMER:PRINTER:LIST']" class="dropdown-item" (click)="onPrinter()" ngbTooltip="Cliquez ici pour imprimer le listing">
          Imprimer <i class="feather icon-printer"></i>
        </a>
        <a class="dropdown-item" (click)="onExport()" ngbTooltip="Cliquez ici pour exporter le listing">
          Exporter <i class="fa fa-file-excel"></i>
        </a>
        <a class="dropdown-item" [hidden]="visible" (click)="onImport()" ngbTooltip="Cliquez ici pour importer un fichier">
          Importer <i class="feather icon-download"></i>
        </a>
        <a class="dropdown-item" [hidden]="visible" (click)="onModel()" ngbTooltip="Cliquez ici pour génerer un modèle">
          Génerer <i class="feather icon-upload"></i>
        </a>
      </div>
    </div>
  </div>

  <!-- LISTE DES CLIENTS -->
  <div class="width list-customer">
    <ng-template ngxPermissionsOnly="CUSTOMER:LIST">
      <div class="col-md-12" *ngIf="type === 'CLIENT'">
        <span class="badge badge-primary mb-4 f-14 width">
          LISTE DES CLIENTS
        </span>
        <app-no-data class="width" [title]="'Aucun client trouvé'" *ngIf="customers && customers.length === 0"></app-no-data>
      </div>
      <div class="col-md-12" *ngIf="type === 'CLIENT' && customers && customers.length > 0">
        <div class="row">
          <div class="col-md-6" *ngFor="let item of customers">
            <app-card [hidHeader]="true" cardClass="user-card user-card-1 mt-4" blockClass="pt-0">
              <div class="user-about-block text-center">
                <div class="row align-items-end">
                  <div class="col text-left pb-3">
                    <span class="badge badge-{{ item?.type === 'ENTREPRISE' ? 'info': 'secondary' }}">{{item?.type}}</span>
                    <span *ngIf="item?.secteur === 'PRIVE' || item?.secteur === 'PUBLIC'" class="badge badge-{{ item?.secteur === 'PRIVE' ? 'success': 'warning' }}">{{item?.secteur === 'PRIVE' ? 'Secteur Privée' : 'Secteur Public' }}</span>
                  </div>
                  <div class="col">
                    <img *ngIf="item?.type === 'PARTICULIER' && item?.sexe === 'Masculin'" class="img-radius img-fluid wid-80 hei-80" [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-default.png'"  onerror="this.onerror=null; this.src='assets/images/avatar-default.png'"/>
                    <img *ngIf="item?.type === 'PARTICULIER' && item?.sexe === 'Féminin'" class="img-radius img-fluid wid-80 hei-80" [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-mlle.jpeg'"  onerror="this.onerror=null; this.src='assets/images/avatar-mlle.jpeg'"/>
                    <img *ngIf="item?.type === 'ENTREPRISE' && item?.photoSrc" class="img-radius img-fluid wid-80 hei-80" [src]="item.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/avatar-default.png'"  onerror="this.onerror=null; this.src='assets/images/avatar-default.png'"/>
                    <i *ngIf="item?.type === 'ENTREPRISE' && !item?.photoSrc" class="fas fa-building fa-5x"></i>
                  </div>
                  <div class="col text-right pb-3">
                    <span class="badge badge-{{ item?.etat === 'INACTIF' ? 'danger': 'success' }}">
                      {{ item?.etat }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <a (click)="showCustomer(item)" href="javascript:">
                  <h4 class="mb-1 mt-3">{{item?.nom}}</h4>
                  <p class="mb-3 text-muted">
                    <b><i class="fas fa-qrcode"></i> {{ item?.code }}</b>
                    <b class="m-4"><i class="feather icon-phone"></i> {{item?.telephone}}</b>
                    <b class="m-4"><i class="feather icon-file-text"></i> {{ item?.nbrDossier }} Dossier(s) actif(s)</b>
                  </p>
                  <p class="mb-1 text-muted">
                    <b> {{ item?.type === 'ENTREPRISE' ? 'Siège social' : 'Domicile' }}  : </b> {{ item?.type === 'ENTREPRISE' ? item?.siegeSocial : item?.domicile }}
                    h4<b class="m-4 text-danger font-weight-bold">{{ item?.impaye < 0 ? 'A REMBOURSER' : 'DOIT' }}: {{item.impaye| number }} {{global.device}}</b>
                  </p>
                </a>
              </div>
              <div class="text-right">
                <button *ngxPermissionsOnly="'CUSTOMER:SHOW'" (click)="showCustomer(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <button *ngxPermissionsOnly="'CUSTOMER:EDIT'" (click)="editCustomer(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                  <i class="feather icon-edit-2"></i>
                </button>
                <button *ngxPermissionsOnly="'CUSTOMER:PRINTER:SHOW'" (click)="printerCustomer(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                  <i class="feather icon-printer"></i>
                </button>
                <ng-template ngxPermissionsOnly="CUSTOMER:DELETE">
                  <button *ngIf="item?.isDelete" (click)="delete(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                    <i class="feather icon-trash"></i>
                  </button>
                </ng-template>
              </div>
            </app-card>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template ngxPermissionsExcept="CUSTOMER:LIST" [ngxPermissionsOnly]="['FOLDER:LIST', 'PAYMENTCUSTOMER:LIST', 'MUTATE:LIST', 'FOLDERTERMINATE:LIST', 'INVOICE:FOLDER:LIST']">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'CLIENT'" [title]="'liste client'"></app-no-droit>
    </div>
  </ng-template>

  <!-- LISTE DES DOSSIERS -->
  <ng-template ngxPermissionsOnly="FOLDER:LIST">
    <div class="col-md-12" *ngIf="type === 'DOSSIER'">
      <span class="badge badge-secondary mb-4 f-14 width">
        LISTE DES DOSSIERS
      </span>
      <app-no-data class="width" [title]="'Aucun dossier trouvé'" *ngIf="folders && folders.length === 0"></app-no-data>
    </div>
    <app-folder-list class="width" [folders]="folders" *ngIf="type === 'DOSSIER' && folders && folders.length > 0"></app-folder-list>
  </ng-template>
  <ng-template ngxPermissionsExcept="FOLDER:LIST">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'DOSSIER'" [title]="'liste dossier'"></app-no-droit>
    </div>
  </ng-template>

  <!-- LISTE DES FACTURES -->
  <ng-template ngxPermissionsOnly="INVOICE:FOLDER:LIST">
    <div class="col-md-12" *ngIf="type === 'FACTURE' && invoices && invoices.length === 0">
      <span class="badge badge-secondary my-2 f-14 formBadge width">
        LISTE DES FACTURES
      </span>
      <app-no-data [title]="'Aucune facture de dossier trouvé'" *ngIf="type === 'FACTURE' && invoices && invoices.length === 0"></app-no-data>
    </div>
    <app-invoice-customer-list class="width" [invoices]="invoices" *ngIf="type === 'FACTURE' && invoices && invoices.length > 0"></app-invoice-customer-list>
  </ng-template>
  <ng-template ngxPermissionsExcept="INVOICE:FOLDER:LIST">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'FACTURE'" [title]="'liste facture dossier'"></app-no-droit>
    </div>
  </ng-template>

  <!-- LISTE DES PAYEMENTS -->
  <ng-template ngxPermissionsOnly="PAYMENT:CUSTOMER:LIST">
    <div class="col-md-12" *ngIf="type === 'PAIEMENT'">
      <span class="badge badge-success my-2 f-14 formBadge width">
        LISTE DES PAIEMENTS
      </span>
      <app-no-data class="width" [title]="'Aucun paiement trouvé'" *ngIf="payments && payments.length === 0"></app-no-data>
    </div>
    <app-payment-customer-list class="width" [payments]="payments" *ngIf="type === 'PAIEMENT' && payments && payments.length > 0"></app-payment-customer-list>
  </ng-template>
  <ng-template ngxPermissionsExcept="PAYMENT:CUSTOMER:LIST">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'PAIEMENT'" [title]="'liste paiement'"></app-no-droit>
    </div>
  </ng-template>

  <!-- LISTE DES MUTATIONS -->
  <ng-template ngxPermissionsOnly="MUTATE:LIST">
    <div class="col-md-12" *ngIf="type === 'MUTATION' && mutates && mutates.length === 0">
      <span class="badge badge-secondary my-2 f-14 formBadge width">
        LISTE DES MUTATIONS
      </span>
      <app-no-data [title]="'Aucune mutation de dossier trouvé'" *ngIf="type === 'MUTATION' && mutates && mutates.length === 0"></app-no-data>
    </div>
    <app-mutate-list class="width" [mutates]="mutates" *ngIf="type === 'MUTATION' && mutates && mutates.length > 0"></app-mutate-list>
  </ng-template>
  <ng-template ngxPermissionsExcept="MUTATE:LIST">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'MUTATION'" [title]="'liste mutation'"></app-no-droit>
    </div>
  </ng-template>

  <!-- LISTE DES RESILIATIONS DE DOSSIER -->
  <ng-template ngxPermissionsOnly="FOLDER:TERMINATE:LIST">
    <div class="col-md-12" *ngIf="type === 'RESILIATION'">
      <span class="badge badge-secondary my-2 f-14 formBadge width">
        LISTE DES RESILIATIONS DE DOSSIER
      </span>
      <app-no-data [title]="'Aucune résiliation de dossier trouvée'" *ngIf="type === 'RESILIATION' && terminates && terminates.length === 0"></app-no-data>
    </div>
    <app-folder-terminate-list class="width" [terminates]="terminates" *ngIf="type === 'RESILIATION' && terminates && terminates.length > 0"></app-folder-terminate-list>
  </ng-template>
  <ng-template ngxPermissionsExcept="FOLDER:TERMINATE:LIST">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'RESILIATION'" [title]="'liste résiliation dossier'"></app-no-droit>
    </div>
  </ng-template>
</div>
