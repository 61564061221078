<div class="col-sm-12">
  <app-card [hidHeader]="true" cardClass="card-datatable">
    <div class="table-responsive">
      <table *ngIf="etat" datatable [dtOptions]="dtOptions"
        class="table table-sm table-striped table-bordered nowrap table-hover">
        <thead class="thead-light">
          <tr>
            <th>Locative</th>
            <th *ngIf="locataire">Locataire</th>
            <th>Periode</th>
            <th>Index de départ </th>
            <th>Index de fin </th>
            <th>Date de Relevé </th>
            <th>Consommation</th>
            <th>Montant</th>
            <th>Crée le</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of electricites">
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.rent?.contract?.rental?.libelle}}</span> <br /> Superficie :
                {{item?.rent?.contract?.rental?.superficie}} m² - {{item?.rent?.contract?.rental?.piece}} pièce(s): <br /> Propriétaire :
                {{item?.rent?.contract?.rental?.house?.owner?.searchableTitle}} <br /> Référence contrat : {{item?.rent?.contract?.code}}
              </p>
            </td>
            <td *ngIf="locataire">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.rent?.contract?.tenant?.searchableTitle}}</span> <br /> Type :
                {{item?.rent?.contract?.tenant?.type}}<br /> Telephone : {{item?.rent?.contract?.tenant?.telephone}}
              </p>
            </td>
            <td class="align-middle"e>{{ item?.rent?.mois }}</td>
            <td class="align-middle">{{item?.debut}}</td>
            <td class="align-middle">{{item?.fin}}</td>
            <td class="align-middle">{{item?.dateP| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
            <td class="align-middle">{{ item?.conso }}</td>
            <td class="align-middle">
              <p class="m-0 d-inline-block align-middle font-16">
                <span class="text-warning">{{item?.montant|number}} {{global.device}}</span>
              </p>
            </td>
            <td class="align-middle">{{item?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
            <td class="table-action">
              <div class="overlay-edit">
                <button (click)="show(item)" type="button"
                  class="btn btn-icon btn-secondary ml-1" ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <button (click)="edit(item)" type="button"
                  class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                  <i class="feather icon-edit-2"></i>
                </button>
                <button *ngxPermissionsOnly="'ELECTRICITE:PRINTER:SHOW'" (click)="printer(item)" type="button"
                  class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                  <i class="feather icon-printer"></i>
                </button>
                <ng-template ngxPermissionsOnly="ELECTRICITE:DELETE">
                  <button type="button"
                    (click)="delete(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                    <i class="feather icon-trash"></i>
                  </button>
                </ng-template>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-card>
</div>
