import {Renew} from '@model/renew';
import { Globals } from '@theme/utils/globals';
import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {RenewService} from '@service/renew/renew.service';
import {EmitterService} from '@service/emitter/emitter.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { RenewContractAddComponent } from '@locataire/renew-contract/renew-contract-add/renew-contract-add.component';
@Component({
  selector: 'app-renew-contract-show',
  templateUrl: './renew-contract-show.component.html',
  styleUrls: ['./renew-contract-show.component.scss']
})
export class RenewContractShowComponent implements OnInit {
  title = '';
  renew: Renew;
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user
  validate = false;

  constructor(
    public modale: NgbActiveModal,
    private modalService: NgbModal,
    private emitter: EmitterService,
    private renewService: RenewService
  ) {
    this.validate = this.renewService.validated
    this.renew = this.renewService.getRenew()
    this.title = "Détails du renouvellement" + this.renew?.code
  }

  ngOnInit(): void {
  }

  editRenew(row) {
    this.modalService.dismissAll();
    this.renewService.setRenew(row);
    this.renewService.edit = true;
    this.modal(RenewContractAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  activateRenew(row){
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment Activer cet renouvellement?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Valider <i class="fas fa-check"></i>',
      confirmButtonColor: 'green',
      timer: 2500
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
      this.renewService.activate(row).subscribe(res => {
        if (res?.status === 'success') {
          if (row) {
            this.modale.close('ferme');
            this.emitter.emit({action: 'RENEW_ACTIVATE', payload: res?.data});
          }
        }
      });
      }
    });
  }

  printerRenew(row): void {
    this.renewService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }

  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {

    }, (reason) => {

    });
  }
}
