
import { Rent } from '@model/rent';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '@theme/utils/globals';
import { Component,OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ValidatorsEnums } from '@theme/enums/validators.enums';
import { RentService } from '@service/rent/rent.service';
import { EmitterService } from '@service/emitter/emitter.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-rent-solde',
  templateUrl: './rent-solde.component.html',
  styleUrls: ['./rent-solde.component.scss']
})
export class RentSoldeComponent implements OnInit {
  title: string = "";
  montantRestant: any = 0;
  form: FormGroup;
  submit: boolean = false;
  edit: boolean = false;
  rent: Rent;
  required = Globals.required;
  userSession = Globals.user;
  global = {country: Globals.country, device: Globals.device};

  constructor(
    public toastr: ToastrService,
    private modalService: NgbModal,
    private formBuild: FormBuilder,
    private emitter: EmitterService,
    public modalActive: NgbActiveModal,
    public rentService: RentService,
  ) {
    this.edit = this.rentService.edit;
    this.rent = this.rentService.getRent();
    this.rent = this.rentService.getRent();
    this.title = 'Modifier le montant de paiement';
    this.newForm();
    this.f.rent.setValue(this.rent.uuid)
  }

  ngOnInit(): void {
    this.editForm();
  }

  newForm() {
    this.form = this.formBuild.group({
      uuid: [null],
      id: [null],
      montant: [0, [Validators.required, Validators.pattern(ValidatorsEnums.number), Validators.min(0)]],
      rent: [0, [Validators.required]]
    });
  }
  editForm() {
    if (this.edit) {
      const data = {...this.rentService.getRent()};
      this.form.patchValue(data);
    }
  }
  onSubmit() {
    this.submit = true;
    if (!this.form.invalid) {
      const data = this.form.getRawValue();
      this.rentService.solde(data).subscribe(res => {
        if (res?.status === 'success') {
          this.modalActive.close('ferme');
        }
      });
    } else { return; }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }

  onClose(){
    this.form.reset()
    this.modalActive.close('ferme');
  }
  onReset(){      this.form.reset()

  }
  toast(msg, title, type) {
    if (type == 'info') {
      this.toastr.info(msg, title);
    } else if (type == 'success') {
      this.toastr.success(msg, title);
    } else if (type == 'warning') {
      this.toastr.warning(msg, title);
    } else if (type == 'error') {
      this.toastr.error(msg, title);
    }
  }
  modal(component, type, size, center, backdrop) {
    return this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {}, (reason) => {});
  }
  get f() { return this.form.controls; }
}
