import * as Highcharts from 'highcharts';
import { Router } from '@angular/router';
import { Globals } from '@theme/utils/globals';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import HC_drilldown from 'highcharts/modules/drilldown';
import { RentService } from '@service/rent/rent.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { OwnerService } from '@service/owner/owner.service';
import {FilterService} from '@service/filter/filter.service';
import { TenantService } from '@service/tenant/tenant.service';
import { DateHelperService } from '@theme/utils/date-helper.service';
import { RentShowComponent } from '@locataire/rent/rent-show/rent-show.component';
import {ApexChartService} from "../../theme/shared/components/chart/apex-chart/apex-chart.service";

HC_drilldown(Highcharts);

@Component({
  selector: 'app-dash-default',
  templateUrl: './dash-default.component.html',
  styleUrls: ['./dash-default.component.scss']
})
export class DashDefaultComponent implements OnInit, OnDestroy  {
  public bandeC: any;
  public bandeM: any;

  //variable du graph
  public Highcharts = Highcharts;
  public barBasicChartOptions: any;

  public lastDate: number;
  public data: any;

  public intervalSub: any;
  public intervalMain: any;
  type: string = 'TOUT';
  typeRow = [
    {label: 'TOUT', value: 'TOUT'}
  ];
  event = {
    categorie: null,
    code: null,
    count: 10,
    create: null,
    dateD: null,
    dateF: null,
    etat: null,
    max: null,
    min: null,
    name: null,
    ordre: "ASC",
    type: "TOUT",
    uuid: null
  }
  widget : any
  listing : any
  global = {country: Globals.country, device: Globals.device}
  dtOptions: any = {};
  etat: boolean = false 
  isPrint: boolean = false 
  publicUrl = environment.publicUrl;
  totalTenant = 0;
  totalRentS = 0;
  totalRentA = 0;
  total = [];
  totalI = [];
  totalP = [];
  mois = [];

  constructor(
    public apexEvent: ApexChartService,
    private modalService: NgbModal,
    private filterService: FilterService,
    private tenantService: TenantService,
    private rentService: RentService,
    private ownerService: OwnerService,
    public router: Router,
    ) {
    this.onFilter(this.event)
    this.data = [];
    this.total = []
    this.totalP = []
    this.totalI = []
    this.mois = []
    this.bandeC = {
      chart: {
        type: 'area',
        height: 100,
        sparkline: { enabled: true }
      },
      colors: ['#4680ff'],
      stroke: { curve: 'smooth', width: 2, },
      series: [ { name: 'Contrat', data: [] }, ],
      tooltip: {
        fixed: { enabled: false },
        x: { show: false },
        y: { title: { formatter: (seriesName) => 'CONTRAT(S) ' } },
        marker: { show: false }
      }
    };
    this.bandeM = {
      chart: {
        type: 'area',
        height: 100,
        sparkline: { enabled: true }
      },
      colors: ['#9ccc65'],
      stroke: { curve: 'smooth', width: 2, },
      series: [ { name: 'Mandat', data: [] }, ],
      tooltip: {
        fixed: { enabled: false },
        x: {  show: false },
        y: { title: { formatter: (seriesName) => 'MANDAT(S) ' } },
        marker: { show: false }
      }
    };

    this.lastDate = 0;

    this.getDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 10, {min: 10, max: 90});
  }

  ngOnInit(): void {
    this.getGraph();
    this.filterService.dashboard(this.event, 'default', null).subscribe(
      res => {
        this.getGraph();
        this.etat = res ? true : false
        this.widget = res?.widget
        this.total = []
        this.totalP = []
        this.totalI = []
        this.mois = []
        this.listing = res?.listing
        res?.facture.forEach(el => {
          this.total.push(el?.total)
          this.totalP.push(el?.paye)
          this.totalI.push(el?.impaye)
          this.mois.push(el?.date)
        });
    }, err => { })
    this.dtOptions = Globals.dataTable;
    this.intervalSub = setInterval(() => {
      this.getNewSeries(this.lastDate, {min: 10, max: 90});
      this.apexEvent.eventChangeSeriesData();
    }, 2000);

    this.intervalMain = setInterval(() => {
      this.resetData();
      this.apexEvent.eventChangeSeriesData();
    }, 60000);
  }

  onFilter($event) {
    this.type = $event.type
    this.filterService.dashboard($event, 'default', null).subscribe(
      res => {
        this.getGraph();
        this.etat = res ? true : false
        this.widget = res?.widget
        this.total = []
        this.totalP = []
        this.totalI = []
        this.mois = []
        this.listing = res?.listing
        res?.facture.forEach(el => {
          this.total.push(el?.total)
          this.totalP.push(el?.paye)
          this.totalI.push(el?.impaye)
          this.mois.push(el?.date)
        });
        this.listing?.tenant.forEach(el => { this.totalTenant = this.totalTenant + el.impaye});
        this.listing?.rentS.forEach(el => { this.totalRentS = this.totalRentS + el.montant});
        this.listing?.rentA.forEach(el => { this.totalRentA = this.totalRentA + el.montant});

        var totalC = []
        var totalM = []
        if (res) {
          res?.contrat.forEach(el => {
            totalC.push(el?.nbr)
          });
          res?.mandat.forEach(el => {
            totalM.push(el?.nbr)
          });
          this.bandeC.series[0].data = totalC.map(x => x)
          this.bandeM.series[0].data = totalM.map(x => x)
        }
    }, err => { })
  }
  onChangeLoad($event) {
    this.type = $event
    if($event === 'TOUT'){
    } else if($event === 'LOYER'){
    } else if($event === 'ENTREE'){
    } else if($event === 'PENALITE'){
    } else if($event === 'AUTRE'){
    } else if($event === 'RENEW'){
    }
  }
  getGraph() {
    this.barBasicChartOptions = {
      chart: {
        type: 'column'
      },
      colors: ['blue', 'green', 'red'],
      title: {
        text: 'STATISTIQUES DE RECOUVREMENT MENSUEL DES LOYERS'
      },
      xAxis: {
        categories: (this.mois),
        crosshair: true
      },
      yAxis: {
        title: {
          text: this.global.device
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>' ,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        color: 'blue',
        name: 'Total',
        data: this.total

      }, {
        color: 'green',
        name: 'Payé',
        data: this.totalP

      }, {
        color: 'red',
        name: 'Impayée',
        data: this.totalI
      },]
    };
  }
  ngOnDestroy() {
    if (this.intervalSub) {
      clearInterval(this.intervalSub);
    }
    if (this.intervalMain) {
      clearInterval(this.intervalMain);
    }
  }
  getDayWiseTimeSeries(baseval, count, yrange) {
    let i = 0;
    while (i < count) {
      const x = baseval;
      const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      this.data.push({x, y});
      this.lastDate = baseval;
      baseval += 86400000;
      i++;
    }
  }
  resetData() {
    this.data = this.data.slice(this.data.length - 10, this.data.length);
  }
  getNewSeries(baseval, yrange) {
    const newDate = baseval + 86400000;
    this.lastDate = newDate;
    this.data.push({
      x: newDate,
      y: Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
    });
  }
  showOwner(row): void {
    this.ownerService.setOwner(row);
    this.router.navigate(['/admin/proprietaire/show/' + row.uuid]);
  }
  showTenant(row) {
    this.tenantService.setTenant(row);
    this.router.navigate(['/admin/locataire/show/' + row.uuid]);
  }
  showRent(row) {
    this.rentService.setRent(row)
    this.modal(RentShowComponent, 'modal-basic-title', 'lg', true, 'static')
  }
  modal(component, type, size, center, backdrop): void {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
  date(date){
    var format = 'MMMM Do, YYYY [at] h:mm:ss A';
    DateHelperService.formatDatefromServer(date, format)
  }
}
