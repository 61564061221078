
import { Payment } from '@model/payment';
import { Globals } from '@theme/utils/globals';
import { environment } from '@env/environment';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {EmitterService} from '@service/emitter/emitter.service';
import { CONFIRMATION, VALIDATION} from '@theme/utils/functions';
import { PaymentService } from '@service/payment/payment.service';
import { UploaderService } from '@service/uploader/uploader.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentAddComponent } from '@locataire/payment/payment-add/payment-add.component';


@Component({
  selector: 'app-payment-show',
  templateUrl: './payment-show.component.html',
  styleUrls: ['./payment-show.component.scss']
})
export class PaymentShowComponent implements OnInit {
  publicUrl = environment.publicUrl;
  title: string = ""
  payment: Payment
  confirmation = CONFIRMATION
  validation = VALIDATION
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user;
  file: any;
  validate = false;

  constructor(
    public modale: NgbActiveModal,
    private modalService: NgbModal,
    private emitter: EmitterService,
    private uploader: UploaderService,
    private paymentService: PaymentService
  ) {
    this.validate = this.paymentService.validated
    this.payment = this.paymentService.getPayment();
    this.title = "Détails du paiement N°" + this.payment?.code;
  }

  ngOnInit(): void {
  }

  editPayment(row) {
    this.modalService.dismissAll()
    this.paymentService.setPayment(row)
    this.paymentService.edit = true
    this.paymentService.type = row.type
    this.modal(PaymentAddComponent, 'modal-basic-title', 'xl', true, 'static')
  }
  validatePayment(row){
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment valider cet paiement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Valider <i class="fas fa-check"></i>',
      confirmButtonColor: 'green',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
      this.paymentService.validate(row).subscribe(res => {
        if (res?.status === 'success') {
          if (row) {
            this.modale.close('ferme');
            this.emitter.emit({action: 'PAYMENT_VALIDATE', payload: res?.data});
          }
        }
      });
      }
    });
  }
  printerPayment(row): void {
    this.paymentService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
  showFile(item) {
    const fileByFolder = this.uploader.getDataFileByFolder();
    this.file = fileByFolder?.path ? this.publicUrl + '/' + fileByFolder?.path : null;
  }
  closeViewer() {
    this.file = '';
    this.uploader.setDataFileByFolder('');
  }
}
