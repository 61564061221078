import { NgModule } from '@angular/core';
import { DashComponent } from './dash/dash.component';
import { Routes, RouterModule } from '@angular/router';
import { DashboardGuard } from '@dashboard/dashboard.guard';
import { DashCrmComponent } from './dash-crm/dash-crm.component';
import { DashDefaultComponent } from '@dashboard/dash-default/dash-default.component';
import { DashProspectionComponent } from './dash-prospection/dash-prospection.component';
import { DashPromotionComponent } from '@dashboard/dash-promotion/dash-promotion.component';
import { DashLocataireComponent } from '@dashboard/dash-locataire/dash-locataire.component';
import { DashLotissementComponent } from '@dashboard/dash-lotissement/dash-lotissement.component';
import { DashProprietaireComponent } from '@dashboard/dash-proprietaire/dash-proprietaire.component';

const routes: Routes = [
  { path: "crm", component: DashCrmComponent },
  { path: "dash", component: DashComponent },
  { path: "principal", component: DashDefaultComponent },
  { path: "prospection", component: DashProspectionComponent },
  { path: "locataire", component: DashLocataireComponent, canActivate: [DashboardGuard] },
  { path: "promotion", component: DashPromotionComponent, canActivate: [DashboardGuard] },
  { path: "lotissement", component: DashLotissementComponent, canActivate: [DashboardGuard] },
  { path: "proprietaire", component: DashProprietaireComponent, canActivate: [DashboardGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
