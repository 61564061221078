
import { Owner } from '@model/owner';
import { House } from '@model/house';
import { Rental } from '@model/rental';
import { Mandate } from '@model/mandate';
import * as Highcharts from 'highcharts';
import { Repayment } from '@model/repayment';
import { environment } from '@env/environment';
import { Globals } from '@theme/utils/globals';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import HC_drilldown from 'highcharts/modules/drilldown';
import { RendService } from '@service/rdv/rend.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ActivatedRoute, Router } from '@angular/router';
import { OwnerService } from '@service/owner/owner.service';
import { HouseService } from '@service/house/house.service';
import { FilterService } from '@service/filter/filter.service';
import { RentalService } from '@service/rental/rental.service';
import { MandateService } from '@service/mandate/mandate.service';
import { UploaderService } from '@service/uploader/uploader.service';
import { ActivityService } from '@service/activity/activity.service';
import { RepaymentService } from '@service/repayment/repayment.service';
import { OwnerAddComponent } from '@proprietaire/owner/owner-add/owner-add.component';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
HC_drilldown(Highcharts);

@Component({
  selector: 'app-owner-show',
  templateUrl: './owner-show.component.html',
  styleUrls: ['./owner-show.component.scss']
})
export class OwnerShowComponent implements OnInit {
  public activeTab: string = 'DASHBOARD';
  publicUrl = environment.publicUrl;
  userSession = Globals.user;
  global = {country: Globals.country, device: Globals.device};
  owner: Owner = null;
  houses: House[];
  rentals: Rental[];
  mandates: Mandate[];
  repayments: Repayment[];
  type: string = 'DASHBOARD';
  etatRow = [
    { label: 'DISPONIBLE', value: 'DISPONIBLE' },
    { label: 'VENDU', value: 'VENDU' }
  ];
  typeRow = [
    { label: 'PROPRIETAIRE', value: 'PROPRIETAIRE' },
    { label: 'BIEN', value: 'BIEN' },
    { label: 'LOCATIVE', value: 'LOCATIVE' },
    { label: 'MANDAT', value: 'MANDAT' },
    { label: 'REVERSEMENT', value: 'REVERSEMENT' }
  ];
  categorieRow = [
    { label: 'EN LOCATION', value: 'LOCATION' },
    { label: 'EN VENTE', value: 'VENTE' }
  ];
  userTitle: string = "Crée par"
  minTitle: string = "Montant MIN"
  maxTitle: string = "Montant MAX"
  categorieTitle: string = "Type de bien"
  etatTitle: string = "Disponibilité ?"
  file: any;
  rdvs = []
  notes = []
  dtOptions: any

  public pieChartData: any;
  @ViewChild('doughnutChart', {static: false}) doughnutChart: ElementRef; // doughnut
  public pieChartTag: CanvasRenderingContext2D;

  //variable du graph
  public Highcharts = Highcharts;
  public barBasicChartOptions: any

  autreTitle = "Locative";
  autre: boolean = true;
  autreType = 'ENTITY';
  autreClass= 'Rental';
  autreNamespace= 'Client';
  autreGroups= 'rental';

  bienTitle: string = "Nom du bien"
  bien: boolean = true
  bienType = 'ENTITY';
  bienClass= 'House';
  bienNamespace= 'Client';
  bienGroups= 'house';

  nameTitle: string = "Propriétaire "
  name: boolean = true;
  nameType = 'TEXT';
  nameClass= 'Owner';
  nameNamespace= 'Client';
  nameGroups= 'owner';
  event = {
    categorie: null,
    code: null,
    count: 10,
    create: null,
    dateD: null,
    dateF: null,
    etat: null,
    max: null,
    min: null,
    name: null,
    autre: null,
    bien: null,
    ordre: "ASC",
    type: "DASHBOARD",
    uuid: null
  }
  constructor(
    private ownerService: OwnerService,
    private houseService: HouseService,
    private rentalService: RentalService,
    private mandateService: MandateService,
    private repaymentService: RepaymentService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private filterService: FilterService,
    private permissionsService: NgxPermissionsService,
    private uploader: UploaderService,
    private activityService: ActivityService,
    private rdvService: RendService,
  ) {
    this.owner = this.ownerService.getOwner();
    this.onChangeLoad(this.type);
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
  }

  ngOnInit(): void {
    this.dtOptions = Globals.dataTable;
    if (!this.owner) {
      this.ownerService.getSingle(this.route.snapshot.params.id).subscribe((res: any) => {
        return this.owner = res;
      });
    }
    this.getPie()
    this.getGraph()
    this.loadData()
  }

  onFilter($event) {
    this.houses = []
    this.rentals = []
    this.mandates = []
    this.repayments = []
    $event.type = this.activeTab
    this.filterService.search($event, 'owner', this.owner.uuid).subscribe(
      res => {
        if (this.activeTab === 'BIEN') {
          return this.houses = res;
        } else if (this.activeTab === 'LOCATIVE') {
          return this.rentals = res;
        } else if (this.activeTab === 'MANDAT') {
          return this.mandates = res;
        } else if (this.activeTab === 'REVERSEMENT') {
          return this.repayments = res;
        } else if (this.activeTab === 'DASHBOARD') {
          return this.repayments = res;
        }
      }, err => { })
  }
  loadData() {
    this.event.autre = this.owner.id
    this.filterService.search(this.event, 'owner', this.route.snapshot.params.id).subscribe(
      res => {
        console.log(res)
        // this.etat = res ? true : false;
        // this.widget = res?.widget;
        // this.listing = res?.listing;
        // this.onSet(res);
        // this.getPie();
        // this._changeDetectorRef.markForCheck();
    }, err => { })
  }
  onChangeLoad(type): void {
    this.activeTab = type;
    if (type === 'PROPRIETAIRE') {
      if (!this.owner) {
        this.ownerService.getSingle(this.route.snapshot.params.id).subscribe((res: any) => {
          return this.owner = res;
        });
      }
    } else if (type === 'BIEN') {
      this.typeRow = [{ label: 'BIEN', value: 'BIEN' }];
      this.name = false
      this.bien = true
      this.bienTitle = "Nom du bien"
      this.categorieRow = []
      this.houseService.getList(this.owner.uuid, null, null).subscribe((res) => {
        return this.houses = res;
      }, error => { }
      );
    } else if (type === 'LOCATIVE') {
      this.categorieTitle = 'Type de locative'
      this.nameTitle = 'Nom du bien'
      this.typeRow = [{ label: 'LOCATIVE', value: 'LOCATIVE' }];
      this.categorieRow = [
        { label: 'STUDIO', value: 'STUDIO' },
        { label: 'APPARTEMENT', value: 'APPARTEMENT' },
        { label: 'PALIER', value: 'PALIER' },
        { label: 'VILLA', value: 'VILLA' },
        { label: 'MAGASIN', value: 'MAGASIN' },
        { label: 'BUREAU', value: 'BUREAU' },
        { label: 'SURFACE', value: 'SURFACE' },
        { label: 'RESTAURANT', value: 'RESTAURANT' },
        { label: 'HALL', value: 'HALL' },
        { label: 'SALLE CONFERENCE', value: 'SALLE CONFERENCE' },
        { label: 'PARKING', value: 'PARKING' }
      ];
      this.etatRow = [
        { label: 'DISPONIBLE', value: 'DISPONIBLE' },
        { label: 'RESERVE', value: 'RESERVE' },
        { label: 'VENDU', value: 'VENDU' }
      ];
      this.rentalService.getList(this.owner.uuid).subscribe((res) => {
        return this.rentals = res;
      }, error => { }
      );
    } else if (type === 'MANDAT') {
      this.nameTitle = 'Nom du bien'
      this.typeRow = [{ label: 'MANDAT', value: 'MANDAT' }];
      this.categorieRow = []
      this.mandateService.getList(this.owner.uuid).subscribe((res) => {
        return this.mandates = res;
      }, error => { }
      );
    } else if (type === 'REVERSEMENT') {
      this.typeRow = [{ label: 'REVERSEMENT', value: 'REVERSEMENT' }];
      this.categorieRow = []
      this.repaymentService.getList(this.owner.uuid, null, null).subscribe((res) => {
        return this.repayments = res;
      }, error => { }
      );
    } else if (type === 'NOTE_INTERNE'){
      this.activityService.getList(null, this.owner.uuid, null, null).subscribe((res: any) => {
        return this.notes = res
      })
      this.rdvService.getList(null, this.owner.uuid, null, null).subscribe((res: any) => {
        return this.rdvs = res
      })
    } else if (type === 'DASHBOARD'){

      this.name = false;
      this.nameTitle = 'Propriétaire'
      this.nameType = 'ENTITY';
      this.nameClass= 'Owner';
      this.nameNamespace= 'Client';
      this.nameGroups= 'owner';

      this.bien = true;
      this.bienTitle = 'Nom du bien';
      this.bienType = 'ENTITY';
      this.bienClass= 'House';
      this.bienNamespace= 'Client';
      this.bienGroups= 'house';

      this.autreTitle = "Locative";
      this.autre = false;
      this.autreType = 'ENTITY';
      this.autreClass= 'Rental';
      this.autreNamespace= 'Client';
      this.autreGroups= 'rental';
    }
  }
  editOwner(row) {
    this.ownerService.setOwner(row);
    this.ownerService.edit = true;
    this.ownerService.type = row.type;
    this.modal(OwnerAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  printerOwner(row): void {
    this.ownerService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  back() { this.router.navigate(['/admin/proprietaire']) }
  delete(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) { }
      else {
        this.ownerService.getDelete(item.uuid).subscribe((res: any) => {
          if (res?.status === 'success') { this.router.navigate(['/admin/proprietaire']) }
        });
      }
    });
  }
  getGraph () {
    this.barBasicChartOptions = {
      chart: {
        type: 'column'
      },
      colors: ['#0e9e4a', '#4680ff', '#ff5252'],
      title: {
        text: "ETAT MENSUEL DES PAIEMENTS."
      },
      xAxis: {
        categories: ([]),
        crosshair: true
      },
      credits: {
        enabled: false
      },
      yAxis: {
        title: {
          text: this.global.device
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>' ,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
      {
        color: '#0e9e4a',
        name: 'Payé',
        data: []

      }, {
        color: '#4680ff',
        name: 'Total',
        data: []

      },{
        color: '#ff5252',
        name: 'Impayeé',
        data: []

      }
    ]
    };
  }
  getPie(){
    setTimeout(() => {
      /* pie cart */
      const pieTag = (((this.doughnutChart.nativeElement as HTMLCanvasElement).children));
      this.pieChartTag = ((pieTag['doughnut_chart']).lastChild).getContext('2d'); // doughnut_chart
      this.pieChartData = {
        labels: ['Attente', 'Impayé', 'En cours', 'Soldé'],
        datasets: [{
          data: [],
          backgroundColor: ['#4680ff', '#ff5252', '#ffa21d', '#0e9e4a'],
          hoverBackgroundColor: ['#4680ff', '#ff5252', '#ffa21d', '#0e9e4a']
        }]
      };
    });
  }
  resetData() {
    // this.data = this.data.slice(this.data.length - 10, this.data.length);
  }

  getNewSeries(baseval, yrange) {
    // const newDate = baseval + 86400000;
    // this.lastDate = newDate;
    // this.data.push({
    //   x: newDate,
    //   y: Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
    // });
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
  showFile(item) {
    const fileByFolder = this.uploader.getDataFileByFolder();
    this.file = fileByFolder?.path ? this.publicUrl + '/' + fileByFolder?.path : null;
  }
  closeViewer() {
    this.file = '';
    this.uploader.setDataFileByFolder('');
  }
}
