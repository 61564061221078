import { Terminate } from '@model/terminate';
import { Globals } from '@theme/utils/globals';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { VALIDATION } from '@theme/utils/functions';
import { NgxPermissionsService } from 'ngx-permissions';
import {EmitterService} from '@service/emitter/emitter.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TerminateService } from '@service/terminate/terminate.service';
import { TerminateAddComponent } from '@locataire/terminate/terminate-add/terminate-add.component';

@Component({
  selector: 'app-terminate-show',
  templateUrl: './terminate-show.component.html',
  styleUrls: ['./terminate-show.component.scss']
})
export class TerminateShowComponent implements OnInit {
  terminate ?: Terminate;
  title: string = ""
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user
  validation = VALIDATION
  validate = false;

  constructor(
    public modale: NgbActiveModal,
    private modalService: NgbModal,
    private emitter: EmitterService,
    private terminateService: TerminateService,
    private permissionsService: NgxPermissionsService
  ) {
    this.terminate = this.terminateService.getTerminate();
    this.validate = this.terminateService.validated
    this.title = "Détails de la résiliation du contrat" + this.terminate?.contract?.libelle
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
  }

  ngOnInit(): void {
  }

  editTerminate(row) {
    this.modalService.dismissAll();
    this.terminateService.setTerminate(row);
    this.terminateService.edit = true;
    this.modal(TerminateAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  printerTerminate(row): void {
    this.terminateService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  activateTerminate(row) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment valider cette résiliation ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Valider <i class="fas fa-check"></i>',
      confirmButtonColor: 'green',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
          this.terminateService.activate(row).subscribe((res) => {
            if (res?.status === 'success') {
              this.modale.close('ferme');
              this.emitter.emit({action: 'TERMINATE_ACTIVATE', payload: res?.data});
            }
        });
      }
    });
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {

    }, (reason) => {

    });
  }
}
