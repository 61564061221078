import { Mandate } from '@model/mandate';
import { Globals } from '@theme/utils/globals';
import { environment } from '@env/environment';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { VALIDATION } from '@theme/utils/functions';
import { NgxPermissionsService } from 'ngx-permissions';
import {EmitterService} from '@service/emitter/emitter.service';
import { MandateService } from '@service/mandate/mandate.service';
import { UploaderService } from '@service/uploader/uploader.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MandateAddComponent } from '@proprietaire/mandate/mandate-add/mandate-add.component';

@Component({
  selector: 'app-mandate-show',
  templateUrl: './mandate-show.component.html',
  styleUrls: ['./mandate-show.component.scss']
})
export class MandateShowComponent implements OnInit {
  title: string = '';
  mandate: Mandate;
  publicUrl = environment.publicUrl;
  global = { country: Globals.country, device: Globals.device }
  userSession = Globals.user;
  validation = VALIDATION
  file: any;
  validate = false;

  constructor(
    public modale: NgbActiveModal,
    private modalService: NgbModal,
    private emitter: EmitterService,
    private uploader: UploaderService,
    private mandateService: MandateService,
    private permissionsService: NgxPermissionsService
  ) {
    this.validate = this.mandateService.validated
    this.mandate = this.mandateService.getMandate();
    this.title = 'Détails du mandate du bien ' + this.mandate?.house?.nom;
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
  }

  ngOnInit(): void {
  }

  editMandale(row) {
    this.modalService.dismissAll();
    this.mandateService.setMandate(row);
    this.mandateService.edit = true;
    this.modal(MandateAddComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  printerMandate(row): void {
    this.mandateService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  valide(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment valider ce mandat ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Valider <i class="fas fa-check"></i>',
      confirmButtonColor: '#9ccc65',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.mandateService.validate(item).subscribe(res => {
          if (res?.code === 200) {
            this.modale.close('ferme');
            this.emitter.emit({action: 'MANDAT_VALIDATE', payload: res?.data});
          }
        }, error => {
          Swal.fire('', error.message, 'error');
        });
      }
    });
  }
  showFile(item) {
    const fileByFolder = this.uploader.getDataFileByFolder();
    this.file = fileByFolder?.path ? this.publicUrl + '/' + JSON.parse(localStorage.getItem('token-zen-data'))?.path + 'signe/mandat/' + fileByFolder?.path : null;
  }
  closeViewer() {
    this.file = '';
    this.uploader.setDataFileByFolder('');
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {
    }, (reason) => {
    });
  }
}
