import { Component, Input, OnInit } from '@angular/core';
import { Folder } from '@model/folder';
import { FolderAddComponent } from '@client/folder/folder-add/folder-add.component';
import { PAYMENT, VALIDATION } from '@theme/utils/functions';
import { FolderService } from '@service/folder/folder.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FolderShowComponent } from '@client/folder/folder-show/folder-show.component';
import {EmitterService} from '@service/emitter/emitter.service';
import { DateHelperService } from '@theme/utils/date-helper.service';
import { Globals } from '@theme/utils/globals';
import { NgxPermissionsService } from 'ngx-permissions';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {InvoiceFolderService} from "@service/invoice-folder/invoice-folder.service";

@Component({
  selector: 'app-folder-list',
  templateUrl: './folder-list.component.html',
  styleUrls: ['./folder-list.component.scss']
})
export class FolderListComponent implements OnInit {
  @Input() folders: Folder[]
  @Input() action: boolean = true
  @Input() validate: boolean = false
  dtOptions: any = {};
  etat: boolean = false
  global = {country: Globals.country, device: Globals.device}
  userSession = Globals.user
  VALIDATION = VALIDATION
  PAYMENT = PAYMENT

  dataSelected: any[] = [];
  form: FormGroup;

  constructor(
    private modalService: NgbModal,
    private emitter: EmitterService,
    private folderService: FolderService,
    private formBuild: FormBuilder,
    private permissionsService: NgxPermissionsService,
    private invoiceFolderService: InvoiceFolderService
  ) {
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
  }

  ngOnInit(): void {
    this.etat = this.folders ? true : false;
    this.dtOptions = Globals.dataTable;
    this.emitter.event.subscribe((data) => {
      if (data.action === 'FOLDER_ADD') {
        this.appendToList(data.payload);
      }
      if (data.action === 'FOLDER_UPDATED') {
        this.update(data.payload);
      }
      if (data.action === 'FOLDER_ACTIVATE') {
        const row = data.payload
        const index = this.folders.findIndex(x => x.id === row.id);
        if (index !== -1) { this.folders.splice(index, 1); }
      }
    });
  }

  newForm(): void {
    this.form = this.formBuild.group({
      valueOne:[null],
      checked: [null],
      checkedAll: this.formBuild.array([]),
    });
  }

  appendToList(row): void {
    this.folders.unshift(row);
  }
  update(row): void {
    const index = this.folders.findIndex(x => x.uuid === row.uuid);
    if (index !== -1) {
      this.folders[index] = row;
    }
  }
  showFolder(row) {
    this.folderService.setFolder(row)
    this.folderService.validated = this.validate
    this.modal(FolderShowComponent, 'modal-basic-title', 'xl', true, 'static')
  }
  editFolder(row) {
    this.folderService.setFolder(row)
    this.folderService.edit = true
    this.modal(FolderAddComponent, 'modal-basic-title', 'xl', true, 'static')
  }

  onCheckAll(event: any) {
    const isChecked = event.target.checked;
    this.dataSelected = isChecked ? this.folders.slice() : [];
    this.updateAllCheckboxes(isChecked);

    console.log(this.dataSelected);

  }

  onCheckItem(item: any) {
    const index = this.dataSelected.indexOf(item);
    if (index === -1) {
      this.dataSelected.push(item);
    } else {
      this.dataSelected.splice(index, 1);
    }
    this.checkIfAllChecked();
    console.log(this.dataSelected);

  }

  updateAllCheckboxes(isChecked: boolean) {
    const checkboxes = document.querySelectorAll('.form-check-input:not(#checkAll)');
    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = isChecked;
    });
  }

  checkIfAllChecked() {
    const allCheckboxes = document.querySelectorAll('.form-check-input:not(#checkAll)');
    const allChecked = Array.from(allCheckboxes).every((checkbox: HTMLElement) => (checkbox as HTMLInputElement).checked);
    const checkAllCheckbox = document.getElementById('checkAll') as HTMLInputElement;
    if (checkAllCheckbox) {
      checkAllCheckbox.checked = allChecked;
    }
  }


  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment valider ce dossier ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Valider <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
         this.onSubmit();
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.dataSelected.forEach((item) => {
        this.checkedAll.push(
          this.formBuild.group({
            uuid: [item?.uuid],
          })
        );
      });
      this.folderService.activate(this.form.getRawValue()).subscribe((res) => {
        if (res?.status === 'success') {
          res?.data.forEach((payload) => {
            this.emitter.emit({action: 'FOLDER_ACTIVATE', payload:payload});
          });
        }
    });

    } else {
      return;
    }
  }

  activateFolder(row) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment valider ce dossier ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Valider <i class="fas fa-check"></i>',
      confirmButtonColor: 'green',
      timer: 4500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
          this.folderService.activate(row).subscribe((res) => {
            if (res?.status === 'success') {
              this.emitter.emit({action: 'FOLDER_ACTIVATE', payload: res?.data});
            }
        });
      }
    });
  }
  printerFolder(row): void {
    this.folderService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  certificateFolder(row): void {
    this.folderService.getPrinter('CERTIFICATE', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  delete(item) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment supprimer cet enrégistrement ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Supprimer <i class="feather icon-trash"></i>',
      confirmButtonColor: '#d33',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
        this.folderService.getDelete(item.uuid).subscribe((res: any) => {
          if (res?.code === 200) {
            const index = this.folders.findIndex(x => x.uuid === item.uuid);
            if (index !== -1) {
              this.folders.splice(index, 1);
            }
            Swal.fire('', res?.message, res?.status);
          }
        });
      }
    });
  }

  normalize(invoice) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment normaliser cette facture ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Normaliser <i class="fas fa-receipt"></i>',
      confirmButtonColor: '#054200',
      timer: 2500,
      reverseButtons: true
    }).then((action) => {
      if (action.dismiss) {
      } else {
        this.invoiceFolderService.normalize(this.userSession.uuid, invoice?.uuid).subscribe(res => {
          if (res?.status === 'success') {
            Swal.fire('', res?.message, 'success');
          }
        })
      }
    });
  }

  printerStandardizedInvoice(standardized) {
    console.log(standardized)
    this.invoiceFolderService.getPrinterStandardizedInvoice(this.userSession?.agencyKey, this.userSession?.uuid, standardized?.uuid);
  }
  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => { }, (reason) => { });
  }
  date(value){ return DateHelperService.readable(value); }
  get checkedAll() { return this.form.get('checkedAll') as FormArray; }

}
