import {Contract} from '@model/contract';
import {ToastrService} from 'ngx-toastr';
import { Globals } from '@theme/utils/globals';
import {Component, OnInit} from '@angular/core';
import { Electricite } from '@model/electricite';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {EmitterService} from '@service/emitter/emitter.service';
import {ContractService} from '@service/contract/contract.service';
import {DateHelperService} from '@theme/utils/date-helper.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ElectriciteService } from '@service/electricite/electricite.service';

@Component({
  selector: 'app-electricite-add',
  templateUrl: './electricite-add.component.html',
  styleUrls: ['./electricite-add.component.scss']
})
export class ElectriciteAddComponent implements OnInit {

  title: string = '';
  type = '';
  form: FormGroup;
  submit = false;
  edit = false;
  electricite: Electricite;
  contracts: Contract[] = [];
  required = Globals.required;

  constructor(
    public modal: NgbActiveModal,
    public toastr: ToastrService,
    private formBuild: FormBuilder,
    public emitter: EmitterService,
    public contractService: ContractService,
    private electriciteService: ElectriciteService,
    private permissionsService: NgxPermissionsService
  ) {
    const permission = JSON.parse(localStorage.getItem('permission-zen')) ? JSON.parse(localStorage.getItem('permission-zen')) : [];
    this.permissionsService.loadPermissions(permission);
    this.edit = this.electriciteService.edit;
    this.electricite = this.electriciteService.getElectricite();
    this.contractService.getList(null, null, 'ALL').subscribe(res => {
      this.contracts = res
    })
    this.title = 'Prélèvement  Consommation d’électricité ';
    this.newForm();
  }

  ngOnInit(): void {
    this.editForm();
  }

  newForm() {
    this.form = this.formBuild.group({
      id: [null],
      uuid: [null],
      periode: [null, [Validators.required]],
      date: [null, Validators.required],
      options: this.formBuild.array([])
    });

    this.onAdd(1);
  }
  editForm() {
    if (this.edit) {
      const data = {...this.electriciteService.getElectricite()};
      data.date = DateHelperService.fromJsonDate(data.dateP);
      data.periode = DateHelperService.fromJsonDate(data.periode);
      this.options.controls = []
      this.options.push(
        this.formBuild.group({
          id: [data.id],
          uuid: [data.uuid],
          contract: [data.contract.uuid, [Validators.required]],
          debut: [data.debut, [Validators.required]],
          fin: [data.fin, [Validators.required]],
          conso: [data.conso, [Validators.required]],
        })
      )
      this.form.patchValue(data);
    }
  }
  onAdd(count) {
    for (let i = 0; i < count; i++) {
      this.options.push(
        this.formBuild.group({
          uuid: [null],
          id: [null],
          contract: [null, [Validators.required]],
          debut: [0, [Validators.required]],
          fin: [0, [Validators.required]],
          conso: [0, [Validators.required]],
        })
      )
    }
  }
  onDelete(row) {
    this.options.removeAt(row);
  }
  onChange() {
    const options = this.form.get('options') as FormArray;
    options.controls.forEach((item: FormGroup) => {
      // Récupérer les valeurs des champs et remplacer les virgules par des points
      // const debutValue = item.get('debut').value.replace(',', '.');
      // const finValue = item.get('fin').value.replace(',', '.');

      // Convertir les valeurs en flottants
      const debut = parseFloat(item.get('debut').value);
      const fin = parseFloat(item.get('fin').value);

      var conso = 0
      if(debut < fin){
        // Calcul de la consommation
        conso = (fin !== 0) ? (fin - debut) : 0;
        conso = parseFloat(conso.toFixed(1));
      }
      console.log(conso)

      // Mettre à jour la valeur du champ consommation
      item.get('conso').setValue(conso)
    });
  }
  onSubmit() {
    this.submit = true;
    if (!this.form.invalid) {
      const data = this.form.getRawValue();
      this.electriciteService.add(data).subscribe(res => {
        if (res?.status === 'success') {
          this.modal.close('ferme');
          if (data?.uuid) {
            this.emitter.emit({action: 'ELECTRICITE_UPDATED', payload: res?.data});
          } else {
            this.emitter.emit({action: 'ELECTRICITE_ADD', payload: res?.data});
          }
        }
      });
    } else { return; }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  onClose(){
    this.form.reset()
    this.modal.close('ferme');
  }
  onReset(){
    this.form.reset()
  }
  toast(msg, title, type) {
    if (type === 'info') {
      this.toastr.info(msg, title);
    } else if (type === 'success') {
      this.toastr.success(msg, title);
    } else if (type === 'warning') {
      this.toastr.warning(msg, title);
    } else if (type === 'error') {
      this.toastr.error(msg, title);
    }
  }
  get f() { return this.form.controls; }
  get options() { return this.form.get('options') as FormArray; }
}
