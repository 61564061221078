<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
    (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <div class="row mb-2" *ngIf="type === 'ELECTRICITE' || type === 'PAIEMENT'">
      <div class="col-md">
        <label>Periode (Mois) </label>
        <input type="date" id="periode" name="periode" [(ngModel)]="periode" class="form-control">
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md">
        <label>Veuillez joindre un fichier excel (.xls)</label>
        <input type="file" id="upload-image-one" name="file" (change)="onFileChangeOne($event)" class="form-control">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="onClose()" type="button" class="btn btn-secondary text-left" data-dismiss="modal">
      Fermer <i class="feather icon-x-circle"></i>
    </button>
    <button (click)="onClose()" type="button" class="btn btn-warning">
      Vider <i class="fas fa-broom"></i>
    </button>
    <button [disabled]="showButton" (click)="onSubmit()" class="btn btn-primary">
      Enregistrer <i class="feather icon-save"></i>
    </button>
  </div>
</div>
