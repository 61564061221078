<div class="col-md-12">
  <app-card [hidHeader]="true" cardClass="card-datatable">
    <form autocomplete="off" [formGroup]="form" (ngSubmit)="onConfirme()">
      <div *ngIf="validate && dataSelected.length>0" class="btn-rental float-right mb-2">
        <button (click)="onConfirme()"  type="submit" class="btn btn-success m-1">
          Valider <i class="feather icon-check-square"></i>
        </button>
      </div>
    <div class="row">
      <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
          <thead class="thead-light">
            <tr>
              <th>
                <div class="form-group">
                  <div class="form-check">
                    <input id="selectAll" class="form-check-input" formControlName="valueOne" type="checkbox" id="checkAll"
                    (change)="onCheckAll($event)">
                  </div>
                </div>
              </th>
              <th *ngIf="owner">Proprietaire</th>
              <th>Libelle</th>
              <th>Etat</th>
              <th>Creé le</th>
              <th>Montant</th>
              <th>Commission</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of repayments ; let i = index">
              <td>
                <div class="form-group">
                  <div class="form-check">
                    <input formControlName="checked" class="form-check-input"  type="checkbox" id="check{{i}}"
                    (change)="onCheckItem(item)">
                  </div>
                </div>
              </td> 
              <td  *ngIf="owner" class="align-middle">
                <p class="m-0 d-inline-block align-middle font-16">
                  <span class="text-warning">{{item?.owner?.searchableTitle}}</span> <br />
                  Type : {{item?.owner?.type}} <br />
                  Telephone : {{item?.owner?.telephone}}
                </p>
              </td>
              <td>
                <p class="m-0 d-inline-block align-middle font-16">
                  <span>Reversement N° {{item?.code}}</span>
                </p><br/>
                <p class="m-0 d-inline-block align-middle font-16">
                  <span>Bien: {{ item?.house?.nom }}</span>
                </p><br/>
                <span *ngIf="item.type === 'VENTE'" class="badge badge-light-primary">Vente</span>
                <span *ngIf="item.type === 'LOCATION'" class="badge badge-light-warning">Location</span>
              </td>
              <td>
                <span *ngIf="item?.etat" class="badge "
                  [ngClass]="{
                    'badge-success' : item?.etat === 'VALIDE',
                    'badge-danger' : item?.etat === 'INVALIDE'
                  }">{{validation(item?.etat)}}</span>
              </td>
              <td class="align-middle">{{item?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' : 'fr-FR'}}</td>
              <td class="align-middle">
                <span class="text-warning"> {{ item?.montant|number }} {{global.device}} </span>
              </td>
              <td class="align-middle">
                <span class="text-warning"> {{ item?.commission|number }} {{global.device}} </span>
              </td>
              <td class="table-action">
                <button *ngxPermissionsOnly="'REPAYMENT:SHOW'" (click)="showRepayment(item)" type="button" class="btn btn-icon btn-secondary"
                  ngbTooltip="Détails">
                  <i class="fas fa-eye"></i>
                </button>
                <ng-template ngxPermissionsOnly="REPAYMENT:EDIT">
                  <button *ngIf="item?.etat === 'INVALIDE'" (click)="editRepayment(item)" type="button" class="btn btn-icon btn-primary ml-1"
                    ngbTooltip="Modifier">
                    <i class="feather icon-edit-2"></i>
                  </button>
                </ng-template>
                <button *ngIf="item?.etat === 'INVALIDE' && validate" (click)="validateRepayment(item)" type="button" class="btn btn-icon  btn-success ml-1" ngbTooltip="VALIDER">
                  <i class="fas fa-check"></i>
                </button>
                <button *ngxPermissionsOnly="'REPAYMENT:PRINTER:SHOW'" (click)="printerRepayment(item)" type="button" class="btn btn-icon btn-warning ml-1"
                  ngbTooltip="Imprimer le décompte">
                  <i class="feather icon-printer"></i>
                </button>
                <ng-template ngxPermissionsOnly="REPAYMENT:INVOICE:PRINTER:SHOW">
                  <button *ngIf="item?.etat === 'VALIDE'" (click)="printerInvoiceRepayment(item?.invoice)" type="button" class="btn btn-icon btn-info ml-1"
                    ngbTooltip="Imprimer la facture">
                    <i class="feather icon-printer"></i>
                  </button>
                </ng-template>
                <ng-template ngxPermissionsOnly="REPAYMENT:DELETE">
                  <button *ngIf="item?.etat === 'INVALIDE'" type="button" (click)="delete(item)" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                    <i class="feather icon-trash"></i>
                  </button>
                </ng-template>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td [attr.colspan]="owner ? 4 : 3" class="font-weight-bold font-size-40 text-right">TOTAL</td>
              <td class="font-weight-bold font-size-40 text-warning"> {{ montant|number }} {{global.device}}</td>
              <td class="font-weight-bold font-size-40 text-warning"> {{ commission|number }} {{global.device}}</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </form>
  </app-card>
</div>
