import { Folder } from '@model/folder';
import { Globals } from '@theme/utils/globals';
import { environment } from '@env/environment';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { VALIDATION } from '@theme/utils/functions';
import { FolderService } from '@service/folder/folder.service';
import {EmitterService} from '@service/emitter/emitter.service';
import { UploaderService } from '@service/uploader/uploader.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FolderAddComponent } from '@client/folder/folder-add/folder-add.component';

@Component({
  selector: 'app-folder-show',
  templateUrl: './folder-show.component.html',
  styleUrls: ['./folder-show.component.scss']
})
export class FolderShowComponent implements OnInit {
  publicUrl = environment.publicUrl;
  title: string = "";
  folder: Folder;
  global = { country: Globals.country, device: Globals.device }
  userSession = Globals.user;
  file: any;
  validation = VALIDATION
  validate = false;

  constructor(
    public modale: NgbActiveModal,
    private modalService: NgbModal,
    private emitter: EmitterService,
    private uploader: UploaderService,
    private folderService: FolderService
  ) {
    this.validate = this.folderService.validated
    this.folder = this.folderService.getFolder()
    this.title = "Détails du dossier de " + this.folder?.customer?.nom
  }

  ngOnInit(): void {
  }

  editFolder(row) {
    this.modalService.dismissAll()
    this.folderService.setFolder(row)
    this.folderService.edit = true
    this.modal(FolderAddComponent, 'modal-basic-title', 'xl', true, 'static')
  }
  printerFolder(row): void {
    this.folderService.getPrinter('SHOW', this.userSession?.agencyKey, this.userSession?.uuid, row?.uuid);
  }
  activateFolder(row) {
    Swal.fire({
      title: '',
      text: 'Voulez-vous vraiment valider ce dossier ?',
      icon: '',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Valider <i class="fas fa-check"></i>',
      confirmButtonColor: 'green',
      timer: 2500,
      reverseButtons: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
      } else {
          this.folderService.activate(row).subscribe((res) => {
            if (res?.status === 'success') {
              this.modale.close('ferme');
              this.emitter.emit({action: 'FOLDER_ACTIVATE', payload: res?.data});
            }
        });
      }
    });
  }

  modal(component, type, size, center, backdrop) {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {

    }, (reason) => {

    });
  }
  showFile(item) {
    const fileByFolder = this.uploader.getDataFileByFolder();
    this.file = fileByFolder?.path ? this.publicUrl + '/' + fileByFolder?.path : null;
  }
  closeViewer() {
    this.file = '';
    this.uploader.setDataFileByFolder('');
  }
}
