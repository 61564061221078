<div class="row justify-content-center">
    <!-- FILTRE DE RECHERCHE -->
    <app-filter class="width"
      [dateD]="true"
      [dateF]="true"
      [type]="true"
      [typeRow]="typeRow"
      [isPrint]="isPrint"
      (typeEvent)="onChangeLoad($event)" 
      (filterEvent)="onFilter($event)">
    </app-filter>

    <!-- WIDGETS -->
    <div class="col-md-12">
      <div class="row">
        <!-- PROPECT -->
        <div class="col-md-3">
          <app-card [hidHeader]="true" [isCardFooter]="true" footerClass="bg-c-blue">
            <div class="row align-items-center">
              <div class="col-8">
                <h4 class="text-c-blue">{{ prospect?.tout|number }}</h4>
                <h6 class="text-muted m-b-0">Prospects</h6>
              </div>
              <div class="col-4 text-right">
                <i class="feather icon-user f-28"></i>
              </div>
            </div>
            <div class="app-card-footer">
              <div class="d-flex justify-content-between align-items-center">
                <p class="text-white m-b-0"><b>{{ prospect?.vente|number }}</b> Ventes</p>
                <p class="text-white m-b-0"><b>{{ prospect?.location|number }}</b> Locations</p>
                <p class="text-white m-b-0"><b>{{ prospect?.both|number }}</b> Les deux</p>
              </div>
            </div>
          </app-card>
        </div>
        <!-- PROPECT END -->

        <!-- OFFRES -->
        <div class="col-md-3">
          <app-card [hidHeader]="true" cardClass="bg-c-yellow order-card">
            <h6 class="text-white">Offres</h6>
            <h2 class="text-white">{{ data?.offre?.nombre|number }}</h2>
            <i class="card-icon feather icon-home"></i>
            <h4 class="m-0 text-white">
              <span class="mr-2"><b>{{ data?.offre?.montant|number }}</b></span>
              <span class="text-ligth">{{global.device}}</span>
            </h4>
          </app-card>
        </div>
        <!-- OFFRES END -->
  
        <!-- PRE-RESERVATION -->
        <div class="col-md-3">
          <app-card [hidHeader]="true" cardClass="bg-c-green order-card">
            <h6 class="text-white">Pré-Réservations</h6>
            <h2 class="text-white">{{ data?.prereservation?.nombre|number }}</h2>
            <i class="card-icon feather icon-file-text"></i>
            <h4 class="m-0 text-white">
              <span class="mr-2"><b>{{ data?.prereservation?.montant|number }}</b></span>
              <span class="text-ligth">{{global.device}}</span>
            </h4>
          </app-card>
        </div>
        <!-- PRE-RESERVATION END -->
  
        <!-- PAIEMENT -->
        <div class="col-md-3">
          <app-card [hidHeader]="true" cardClass="bg-c-red order-card">
            <h6 class="text-white">Paiements</h6>
            <h2 class="text-white">{{ data?.payement?.montant|number }}</h2>
            <i class="card-icon feather icon-credit-card"></i>
            <h4 class="m-0 text-white">
              <span class="mr-2"><b>{{ data?.payement?.nombre|number }}</b></span>
              <span class="text-ligth">{{global.device}}</span>
            </h4>
          </app-card>
        </div>
        <!-- PAIEMENT END -->
      </div>
    </div>

    <!-- GRAPHE -->
    <div class="col-md-12 mb-4">
      <lib-highcharts-angular 
        [Highcharts]="Highcharts" 
        [options]="barBasicChartOptions"
        style="width: 100%; height: 450px; display: block;">
      </lib-highcharts-angular>
    </div>
    <!-- GRAPHE END -->

    <!-- LISTINGS -->
    <div class="col-md-12">
      <div class="row">
        <!-- LISTE DES 10 DERNIERES PRÉ-RÉSERVATIONS -->
        <div class="col-md-12">
          <app-card [hidHeader]="true">
            <span class="badge badge-success my-2 f-14 formBadge width">LISTE DES 10 DERNIERES PRÉ-RÉSERVATIONS</span>
            <div class="table-responsive" *ngIf="listing?.lastPrereservation && listing?.lastPrereservation?.length > 0">
              <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>Numéro</th>
                    <th>Statut</th>
                    <th>État</th>
                    <th>Date</th>
                    <th>Montant</th>
                    <th>Prospect</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of listing?.lastPrereservation" class="align-middle">
                    <td><b>{{ item?.numero }}</b></td>
                    <td>
                      <span class="badge badge-{{item?.status === 'ATTENTE' ? 'warning' : item?.status === 'TERMINE' ? 'success' : 'danger'}}">
                        {{ item?.status }}
                      </span>
                    </td>
                    <td>
                      <span class="badge badge-{{item?.etat === 'INVALIDE' ? 'warning' : item?.etat === 'VALIDE' ? 'success' : 'danger'}}">
                        {{ item?.etat }}
                      </span>
                    </td>
                    <td>{{ item?.date|date: "d MMMM y" : '' : 'fr-FR' }}</td>
                    <td class="text-warning">{{ item?.montant|number }} {{ global.device }}</td>
                    <td>
                      <a href=javascript:void(0) (click)="showProspect(item?.prospect)">{{ item?.prospect?.nom }}</a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="4" class="font-weight-bold font-size-40 text-right">TOTAL</td>
                    <td class="font-weight-bold font-size-40 text-success"> {{ montantLP |number}} {{global.device}}</td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <app-no-data [title]="'Aucune donnée trouvée'" *ngIf="listing?.lastPrereservation && listing?.lastPrereservation?.length === 0"></app-no-data>
          </app-card>
        </div>

        <!-- LISTE DES PRÉ-RÉSERVATIONS EN ATTENTE DE VALIDATION -->
        <div class="col-md-6">
          <app-card [hidHeader]="true">
            <span class="badge badge-warning my-2 f-14 formBadge width">LISTE DES PRÉ-RÉSERVATIONS EN ATTENTE DE VALIDATION</span>
            <div class="table-responsive" *ngIf="listing?.invalidPrereservation && listing?.invalidPrereservation?.length > 0">
              <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>Numéro</th>
                    <th>État</th>
                    <th>Date</th>
                    <th>Montant</th>
                    <th>Prospect</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of listing?.invalidPrereservation" class="align-middle">
                    <td><b>{{ item?.numero }}</b></td>
                    <td>
                      <span class="badge badge-{{item?.etat === 'INVALIDE' ? 'warning' : item?.etat === 'VALIDE' ? 'success' : 'danger'}}">
                        {{ item?.etat }}
                      </span>
                    </td>
                    <td>{{ item?.date|date: "d MMMM y" : '' : 'fr-FR' }}</td>
                    <td class="text-warning">{{ item?.montant|number }} {{ global.device }}</td>
                    <td>
                      <a href=javascript:void(0) (click)="showProspect(item?.prospect)">{{ item?.prospect?.nom }}</a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3" class="font-weight-bold font-size-40 text-right">TOTAL</td>
                    <td class="font-weight-bold font-size-40 text-success"> {{ montantIP |number}} {{global.device}}</td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <app-no-data [title]="'Aucune donnée trouvée'" *ngIf="listing?.invalidPrereservation && listing?.invalidPrereservation?.length === 0"></app-no-data>
          </app-card>
        </div>

        <!-- LISTE DES PRÉ-RÉSERVATIONS EN ATTENTE DE FINALISATION -->
        <div class="col-md-6">
          <app-card [hidHeader]="true">
            <span class="badge badge-secondary my-2 f-14 formBadge width">LISTE DES PRÉ-RÉSERVATIONS EN ATTENTE DE FINALISATION</span>
            <div class="table-responsive" *ngIf="listing?.impayePrereservation && listing?.impayePrereservation?.length > 0">
              <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-bordered nowrap table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>Numéro</th>
                    <th>Statut</th>
                    <th>Date</th>
                    <th>Montant</th>
                    <th>Prospect</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of listing?.impayePrereservation" class="align-middle">
                    <td><b>{{ item?.numero }}</b></td>
                    <td>
                      <span class="badge badge-{{item?.status === 'ATTENTE' ? 'warning' : item?.status === 'TERMINE' ? 'success' : 'danger'}}">
                        {{ item?.status }}
                      </span>
                    </td>
                    <td>{{ item?.date|date: "d MMMM y" : '' : 'fr-FR' }}</td>
                    <td class="text-warning">{{ item?.montant|number }} {{ global.device }}</td>
                    <td>
                      <a href=javascript:void(0) (click)="showProspect(item?.prospect)">{{ item?.prospect?.nom }}</a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3" class="font-weight-bold font-size-40 text-right">TOTAL</td>
                    <td class="font-weight-bold font-size-40 text-success"> {{ montantImP |number}} {{global.device}}</td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <app-no-data [title]="'Aucune donnée trouvée'" *ngIf="listing?.impayePrereservation && listing?.impayePrereservation?.length === 0"></app-no-data>
          </app-card>
        </div>
      </div>
    </div>
</div>