import { Rent } from '@model/rent';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { map, catchError } from "rxjs/operators";
import { ApiService, ApiUrlService } from '@theme/utils/api.service';
import { NoInternetHelper } from '@theme/utils/no-internet-helper';

@Injectable({
  providedIn: 'root'
})
export class RentService {
  rent: Rent;
  public edit: boolean = false;
  public type: string = "";
  private urlBase = environment.publicUrl;
  private namespace = "agency/rent";
  private url ='private/agency/rent';

  constructor(private api: ApiService, private apiUrl: ApiUrlService) {}

  setRent(rent: Rent) {
    this.rent = rent
  }
  getRent(): Rent {
    return this.rent
  }
  add(data: Rent): Observable<any> {
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return Observable.create(obs => {
        obs.next();
        obs.complete();
      });
    }

    if (data.uuid) {
      return this.update(data);
    } else {
      return this.create(data);
    }
  }
  create(data: Rent): Observable<any> {
    return this.api._post(`${this.url}/new`, data).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error))
    );
  }
  solde(data: Rent): Observable<any> {
    return this.api._post(`${this.url}/solde`, data).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error))
    );
  }
  checkEtat(data): Observable<any> {
    return this.api._post(`${this.url}/check`, data).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error))
    );
  }
  update(data: Rent): Observable<any> {
    return this.api._post(`${this.url}/${data.uuid}/edit`, data).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error))
    );
  }
  getList(tenant?: string, contract?: string, all: string = null): Observable<Rent[]> {
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return Observable.create(obs => {
        obs.next();
        obs.complete();
      });
    }

    return this.api._get(`${this.url}/`, {
      tenant: tenant,
      all:all,
      contract: contract
    }).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error))
    );
  }
  getSingle(uuid: string, mois: string, contract: string): Observable<Rent> {
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return Observable.create(obs => {
        obs.next();
        obs.complete();
      });
    }

    return this.api._get(`${this.url}/show`, {
      contract: contract,
      mois: mois,
      uuid: uuid
    }).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error))
    );
  }
  getPrinter(type: string, agencyKey: string, userKey: string, data: any): void {
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return;
    }

    let url = 'printer/' + this.namespace + '/' + agencyKey + '/' + userKey;
    if(type === 'LISTE') {
      if(data && data !== undefined) {
        for (const k in data) {
          if (data.hasOwnProperty(k)) {
            if(k !== 'uuid') {
              url += '/' + (data[k] !== undefined && data[k] !== '' ? data[k] : null);
            }
          }
        }
      } else {
        url += '/LOYER/null/null/null/null/null/null/null/null/null/DESC/null/null/null/null/10/null'
      }
    }
    if(type === 'SHOW') {
      url = (data) ? url +'/' + data : url;
    }
    window.open(`${this.urlBase}/${url}`, '_blank');
  }

  // getPrinterProforma(type: string, data: any): void {
  //   if (!navigator.onLine) {
  //     NoInternetHelper.internet()
  //     return;
  //   }
  //   console.log(data)
  //   let url = 'printer/' + this.namespace + '/' + 'proforma';
  //     if(data && data !== undefined) {
  //       for (const k in data) {
  //         if (data.hasOwnProperty(k)) {
  //           if(k !== 'uuid') {
  //             url += '/' + (data[k] !== undefined && data[k] !== '' ? data[k] : null);
  //           }
  //         }
  //       }
  //   }
  //   window.open(`${this.urlBase}/${url}`, '_blank');
  // }

  getPrinterProforma(type: string, agencyKey: string, userKey: string, data: any): void {
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return;
    }

    let url = 'printer/' + this.namespace + '/' + agencyKey + '/' + userKey;
    if(type === 'LISTE') {
      if(data && data !== undefined) {
        for (const k in data) {
          if (data.hasOwnProperty(k)) {
            if(k !== 'uuid') {
              url += '/' + (data[k] !== undefined && data[k] !== '' ? data[k] : null);
            }
          }
        }
      } else {
        url += '/RENT/null/null/null/null/null/null/null/null/null/DESC/null/null/null/null/10/null'
      }
    }
    if(type === 'SHOW') {
      url = (data) ? url +'/'+ 'proforma/'+ data : url;
    // printer/agency/rent/696341a3-fa3c-4e99-b33d-1952f3ead11c/4fbc01db-4130-4d02-86c4-002cb56c7a48/proforma/61f70306-b23c-4e96-983f-a064fda25b98
    }
    console.log(data, url)

    window.open(`${this.urlBase}/${url}`, '_blank');
  }


  getPrinterAssocie(data: any): Observable<any> {
    return this.apiUrl._post(`printer/agency/rent/associe`, data).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error))
    );
  }

  // getPrinterProforma(data: any): Observable<any> {
  //   return this.apiUrl._post(`printer/agency/rent/proforma`, data).pipe(
  //     map((response: any) => response),
  //     catchError((error: any) => throwError(error))
  //   );
  // }

  getExport(agencyKey: string, userKey: string, data: any): void {
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return;
    }

    let url = 'export/rent/' + agencyKey + '/' + userKey;
    if(data && data !== undefined) {
      for (const k in data) {
        if (data.hasOwnProperty(k)) {
          if(k !== 'uuid') {
            url += '/' + data[k];
          }
        }
      }
    } else {
      url += '/LOYER/null/null/null/null/null/null/null/null/null/DESC/null/null/null/null/10/null'
    }
    window.open(`${this.urlBase}/${url}`);
  }
  getGenerer(){
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return Observable.create(obs => {
        obs.next();
        obs.complete();
      });
    }
    var url = this.urlBase + '/import/agency/model/rent'
    window.open(`${url}`);
  }
  getGenererElectricite(){
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return Observable.create(obs => {
        obs.next();
        obs.complete();
      });
    }
    var url = this.urlBase + '/import/agency/model/electricite'
    window.open(`${url}`);
  }
  import(data){
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return Observable.create(obs => {
        obs.next();
        obs.complete();
      });
    }
    var url = 'private/import/agency'
    return this.api._post(`${url}/rent`, data).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error))
    );
  }
  importElectricite(data){
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return Observable.create(obs => {
        obs.next();
        obs.complete();
      });
    }
    var url = 'private/import/agency'
    return this.api._post(`${url}/electricite`, data).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error))
    );
  }
  getDelete(uuid: string): Observable<any> {
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return Observable.create(obs => {
        obs.next();
        obs.complete();
      });
    }

    return this.api._delete(`${this.url}/${uuid}/delete`).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error))
    );
  }
}
