<div class="row justify-content-center">
  <!-- FILTRE DE RECHERCHE -->
  <div class="width btn-filter-owner">
    <ng-template
      [ngxPermissionsOnly]="['HOUSE:LIST', 'RENTAL:LIST']">
      <app-filter class="width" [name]="true" [nameTitle]="nameTitle" [user]="true" [userTitle]="userTitle"
        [bien]="bien" [bienTitle]="bienTitle" [etat]="etat" [etatRow]="etatRow" [etatTitle]="etatTitle"
        [categorie]="true" [categorieRow]="categorieRow" [categorieTitle]="categorieTitle" [dateD]="true" [dateF]="true"
        [create]="true" [min]="min" [minTitle]="minTitle" [max]="max" [maxTitle]="maxTitle" [ordre]="true"
        [code]="true" [count]="true" [type]="true" [typeRow]="typeRow" (typeEvent)="onChangeLoad($event)"
        (filterEvent)="onFilter($event)">
      </app-filter>
    </ng-template>
  </div>
  <ng-template
    [ngxPermissionsExcept]="['HOUSE:LIST', 'RENTAL:LIST']">
    <div class="col-md-12">
      <app-no-droit [title]="'propriétaire'"></app-no-droit>
    </div>
  </ng-template>

  <!-- BOUTONS DE GESTION -->
  <div class="col-sm-12 mb-4 btn-all-owner">
    <div class="first">
      <div class="btn-house">
        <div *ngxPermissionsOnly="'HOUSE:NEW'" class="btn-group m-1" ngbDropdown
          ngbTooltip="Cliquez ici pour ajouter un bien">
          <button class="btn btn-secondary" ngbDropdownToggle type="button">
            Bien <i class="feather icon-home"></i>
          </button>
          <div ngbDropdownMenu>
            <a *ngIf="autorisation?.CONTRAT" class="dropdown-item" (click)="addHouse('LOCATION')">En location</a>
            <a *ngIf="autorisation?.DOSSIER" class="dropdown-item" (click)="addHouse('VENTE')">En vente</a>
          </div>
        </div>
      </div>
      <div class="btn-rental">
        <button *ngxPermissionsOnly="'RENTAL:NEW'" (click)="addRental()" type="button" class="btn btn-info m-1"
          ngbTooltip="Cliquez ici pour ajouter une locative">
          Locative <i class="fas fa-home"></i>
        </button>
      </div>
      <div class="btn-rental">
        <button (click)="addValidateur()" type="button" class="btn btn-success m-1"
          ngbTooltip="Cliquez ici pour Definir Mes Validateurs">
          Definir Mes Validateurs <i class="feather icon-check-square"></i>
        </button>
      </div>
    </div>
    <div class="btn-group float-right m-1 btn-outil-owner" ngbDropdown>
      <a class="btn btn-secondary mr-1" (click)="onChangeView()" ngbTooltip="Cliquez ici pour changer de vue">
        <i class="feather icon-grid" *ngIf="view"></i>
        <i class="fas fa-table" *ngIf="!view"></i>
      </a>
      <button class="btn btn-warning" ngbDropdownToggle type="button">
        Outils <i class="fas fa-tools"></i>
      </button>
      <div class="dropdown-menu-right" ngbDropdownMenu>
        <a *ngxPermissionsOnly="['OWNER:PRINTER:LIST']" class="dropdown-item" (click)="onPrinter()"
          ngbTooltip="Cliquez ici pour imprimer le listing">
          Imprimer <i class="feather icon-printer"></i>
        </a>
        <a class="dropdown-item" (click)="onExport()" ngbTooltip="Cliquez ici pour exporter le listing">
          Exporter <i class="fa fa-file-excel"></i>
        </a>
        <a class="dropdown-item" [hidden]="visible" (click)="onImport()"
          ngbTooltip="Cliquez ici pour importer un fichier">
          Importer <i class="feather icon-download"></i>
        </a>
        <a class="dropdown-item" [hidden]="visible" (click)="onModel()" ngbTooltip="Cliquez ici pour génerer un modèle">
          Génerer <i class="feather icon-upload"></i>
        </a>
      </div>
    </div>
  </div>


  <!-- LISTE DES BIENS -->
  <ng-template ngxPermissionsOnly="HOUSE:LIST">
    <div class="col-md-12" *ngIf="type === 'BIEN' && houses && houses.length === 0">
      <span class="badge badge-secondary mb-4 f-14 width">
        LISTE DES BIENS
      </span>
      <app-no-data [title]="'Aucun bien trouvé'" *ngIf="type === 'BIEN' && houses && houses.length === 0"></app-no-data>
    </div>
    <app-house-list [houses]="houses" *ngIf="type === 'BIEN' && houses && houses.length > 0" class="width">
    </app-house-list>
  </ng-template>
  <ng-template ngxPermissionsExcept="HOUSE:LIST">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'BIEN'" [title]="'liste bien'"></app-no-droit>
    </div>
  </ng-template>

  <!-- LISTE DES LOCATIVES -->
  <ng-template ngxPermissionsOnly="RENTAL:LIST">
    <div class="col-md-12" *ngIf="type === 'LOCATIVE'">
      <span class="badge badge-info mb-4 f-14 width">
        LISTE DES LOCATIVES
      </span>
      <app-card [hidHeader]="true" cardClass="card-datatable" *ngIf="rentals && rentals.length > 0">
        <div class="row">
          <app-rental-list [rentals]="rentals" [action]="true" class="width"></app-rental-list>
        </div>
      </app-card>
      <app-no-data [title]="'Aucune locative trouvée'" *ngIf="rentals && rentals.length === 0"></app-no-data>
    </div>
  </ng-template>
  <ng-template ngxPermissionsExcept="RENTAL:LIST">
    <div class="col-md-12">
      <app-no-droit *ngIf="type === 'LOCATIVE'" [title]="'liste locative'"></app-no-droit>
    </div>
  </ng-template>


</div>
