import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { Globals } from '@theme/utils/globals';
import { Component, OnInit } from '@angular/core';
import HC_drilldown from 'highcharts/modules/drilldown';
import { FilterService } from '@service/filter/filter.service';

HC_drilldown(Highcharts);

@Component({
  selector: 'app-dash-crm',
  templateUrl: './dash-crm.component.html',
  styleUrls: ['./dash-crm.component.scss']
})
export class DashCrmComponent implements OnInit {
  mois = [];
  data: any;
  total = [];
  listing: any;
  prospect: any;
  dtOptions: any = {};
  type: string = 'TOUT';
  montantLP: number = 0;
  montantIP: number = 0;
  montantImP: number = 0;
  isPrint: boolean = true;
  public Highcharts = Highcharts;
  public barBasicChartOptions: any;
  global = {country: Globals.country, device: Globals.device};
  typeRow = [
    {label: 'TOUT', value: 'TOUT'}
  ];
  event = {
    categorie: null,
    code: null,
    count: 10,
    create: null,
    dateD: null,
    dateF: null,
    etat: null,
    max: null,
    min: null,
    name: null,
    ordre: "ASC",
    type: "TOUT",
    uuid: null
  }

  constructor(
    public router: Router,
    private filterService: FilterService,
  ) {
  }
  
  ngOnInit(): void {
    this.onFilter(this.event);
    this.dtOptions = Globals.dataTable;
  }

  onFilter($event) {
    this.type = $event.type
    this.filterService.dashboard(this.event, 'crm', null).subscribe(
      res => {
        this.getGraph();
        this.data = res;
        this.listing = res?.listing;
        this.prospect = res?.prospect;

        if(this.listing?.lastPrereservation?.length > 0) {
          this.listing?.lastPrereservation.forEach(item => {
            this.montantLP += item?.montant
          });
        }
        if(this.listing?.invalidPrereservation?.length > 0) {
          this.listing?.invalidPrereservation.forEach(item => {
            this.montantIP += item?.montant
          });
        }
        if(this.listing?.impayePrereservation?.length > 0) {
          this.listing?.impayePrereservation.forEach(item => {
            this.montantImP += item?.montant
          });
        }
        this.data?.graph.forEach(el => {
          this.total.push(el?.total)
          this.mois.push(el?.date)
        });

      },err => { }
    )
  }

  onChangeLoad($event) {
    this.type = $event
    if($event === 'TOUT'){
    }
  }

  showProspect(propect) {
    this.router.navigate(['/admin/prospection/show/' + propect.uuid]);
  }

  getGraph() {
    this.barBasicChartOptions = {
      chart: {
        type: 'column'
      },
      colors: ['green'],
      title: {
        text: 'DIAGRAMME MENSUEL DES PRÉ-RÉSERVATIONS'
      },
      xAxis: {
        categories: (this.mois),
        crosshair: true
      },
      yAxis: {
        title: {
          text: this.global.device
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>' ,
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
        { color: 'green', name: 'Pré-Réservation', data: this.total }
      ]
    };
  }

}
